import React from "react";
import { showPertussisExpectedDueDateWarning } from "../vaccination.functions";

interface PertussisDueDateWarningProps {
  assessmentDate: Date;
  expectedDueDate: Date;
}

const PertussisDueDateWarning = ({
  assessmentDate,
  expectedDueDate,
}: PertussisDueDateWarningProps) => {
  if (!showPertussisExpectedDueDateWarning(assessmentDate, expectedDueDate)) {
    return null;
  }

  return (
    <div className="nhsuk-warning-callout">
      <h2 className="nhsuk-warning-callout__label">
        <span role="text">
          <span className="nhsuk-u-visually-hidden">Important: </span>
          Warning
        </span>
      </h2>
      <p>
        The vaccine is recommended between 16 to 32 weeks of pregnancy. For
        guidance, visit{" "}
        <a
          href="https://assets.publishing.service.gov.uk/media/667dee0bc7f64e2342090116/Green_Book_Chapter_24_260624.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Pertussis: the green book, chapter 24.
        </a>
      </p>
    </div>
  );
};

export default PertussisDueDateWarning;
