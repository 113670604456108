import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useUser } from "../UserProvider";
import { User } from "../user.models";
import { NhsdOrganisationExists } from "../../../_shared/shared.models";
import regionalService from "../../../_shared/services/regional/regional.service";
import organisationService from "../../../_shared/services/organisation/organisation.service";
import { BackLink } from "nhsuk-react-components";
import { LeadUserFormValues } from "../../regional/models/organisation.models";
import { Loading } from "../../../_shared/components/Loading";

const initialValues: LeadUserFormValues = {
  firstName: "",
  lastName: "",
  email: "",
};

export interface RegionalUserContext {
  user: User;
  organisation: NhsdOrganisationExists;
  leadUserFormValues: LeadUserFormValues;
  setLeadUserFormValues: React.Dispatch<
    React.SetStateAction<LeadUserFormValues>
  >;
  focusField: string;
  setFocusField: React.Dispatch<React.SetStateAction<string>>;
}

interface OrganisationRootProps {
  isNewOrg: boolean;
}

const OrganisationRoot: React.FC<OrganisationRootProps> = ({ isNewOrg }) => {
  const user = useUser();
  const [leadUserFormValues, setLeadUserFormValues] =
    useState<LeadUserFormValues>(initialValues);
  const [focusField, setFocusField] = useState<string>("");
  const { code: OdsCode } = useParams<{ code: string }>();
  const [organisation, setOrganisation] =
    useState<NhsdOrganisationExists | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadOrganisationDetails = async () => {
      try {
        setIsLoading(true);
        if (isNewOrg) {
          const regionalResult = await regionalService.searchOrganisations$(
            OdsCode,
            true,
          );
          const regionalOrg = regionalResult[0];

          setOrganisation({
            Exists: false,
            Name: regionalOrg.Name,
            OrgId: OdsCode,
            Id: regionalOrg.Id,
            PrimaryRoleDescription: regionalOrg.PrimaryRoleDescription,
            Status: regionalOrg.Status,
            OrgRecordClass: regionalOrg.OrgRecordClass,
            PostCode: regionalOrg.PostCode,
            LastChangeDate: regionalOrg.LastChangeDate,
            PrimaryRoleId: regionalOrg.PrimaryRoleId,
            OrgLink: regionalOrg.OrgLink,
            Town: regionalOrg.Town,
            AddressLine1: regionalOrg.AddressLine1,
            AddressLine2: regionalOrg.AddressLine2,
            County: regionalOrg.County,
            LegalEndDate: regionalOrg.LegalEndDate,
          });
        } else {
          const orgExists =
            await organisationService.checkOrganisation$(OdsCode);

          if (!orgExists.Exists) {
            setOrganisation(null);
          } else {
            setOrganisation({
              Exists: orgExists.Exists,
              Name: orgExists.Name,
              OrgId: OdsCode,
              Id: orgExists.Id,
              Region: orgExists.Region,
              PrimaryRoleDescription: orgExists.PrimaryRoleDescription,
            });
          }
        }
      } catch (err) {
        console.error("Error fetching organisation details:", err);
      } finally {
        setIsLoading(false);
      }
    };

    loadOrganisationDetails();
  }, [OdsCode, isNewOrg]);

  if (isLoading) {
    return <Loading message="Organisation details loading..." />;
  }

  if (!organisation) {
    return (
      <>
        <BackLink asElement="button" onClick={() => window.history.back()}>
          Back
        </BackLink>
        <div className="nhsuk-grid-row">
          <div className="nhsuk-grid-column-two-thirds">
            <h1 className="nhsuk-heading-l">Organisation does not exist</h1>
          </div>
        </div>
      </>
    );
  }

  const context: RegionalUserContext = {
    user,
    organisation,
    leadUserFormValues,
    setLeadUserFormValues,
    focusField,
    setFocusField,
  };

  return <Outlet context={context} />;
};

export default OrganisationRoot;
