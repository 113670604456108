import axios, { AxiosError } from "axios";
import { APP_BASE_URL } from "./shared.constants";
import userService from "../components/user/user.service";
import { UserPaths } from "../components/user/user.enums";
import vaccinatorLocationService from "../components/vaccinator-location/vaccinator-location.service";
import { redirect } from "react-router-dom";
import sessionService from "./services/session/session.service";

interface CustomAxiosOptions {
  /**
   * If true, the axios instance will handle server errors and show the appropriate error message.
   * Set to false if you want to handle server errors yourself in the calling code.
   * Eventually, all UIs should handle server errors appropriately, and this option should be removed.
   */
  handleErrors: boolean;
}

const createAxiosInstance = ({ handleErrors = false }: CustomAxiosOptions) => {
  const appAxios = axios.create({
    baseURL: APP_BASE_URL,
    timeout: 30000,
  });

  appAxios.interceptors.request.use(
    (request) => {
      // Refresh the session timestamp.
      // The session cookie is HTTP Only so we can't ready it directly, therefore we need to maintain a timestamp in session storage.
      // TODO - Question- Is this always hitting the RAVS back-end?
      sessionService.refreshSessionTimestamp();
      return request;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  appAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError) => {
      const status = error?.response?.status;
      //Validation errors
      if (status === 400) {
        alert(get400Error(error));
      } //Unauthorised
      else if (status === 401) {
        userService.setUser(null);
        vaccinatorLocationService.setVaccinatorLocation(null);

        redirect(UserPaths.Login);
      } // Internal Server Error
      else if (status >= 500 && status < 600) {
        if (handleErrors) {
          if (window.location.pathname !== UserPaths.ServiceUnavailable) {
            window.location.href = UserPaths.ServiceUnavailable;
          }
          return false;
        }
      } //Forbidden
      else if (status === 403) {
        window.location.href = UserPaths.AccessDenied;
      } else {
        if (handleErrors) {
          alert("Network error. Check your internet connection and try again.");
        }

        console.error(
          "Network error. We received no response from the server.",
        );
      }

      return Promise.reject(error);
    },
  );

  return appAxios;
};

export const appAxios = createAxiosInstance({ handleErrors: true });

export const appAxiosWithErrors = createAxiosInstance({ handleErrors: false });

function get400Error(error: AxiosError): string {
  let errorMessage = "Server validation error! ";
  const data = error.response.data as any;
  if (data && data.status == 400) {
    const errors = data.errors;
    if (errors) {
      const keys = Object.keys(errors);
      for (const k of keys) {
        errorMessage += errors[k] + " ";
      }
    }
  }

  return errorMessage;
}
