import React, { useEffect, useState, useMemo } from "react";
import { RoleIds } from "../user/user.enums";
import { User } from "../user/user.models";
import userManagementService from "../../_shared/services/user-management/userManagement.service";
import NhsTable from "../../_shared/components/NHSUK/NhsTable";
import { BackLink, Button } from "nhsuk-react-components";
import { Loading } from "../../_shared/components/Loading";
import { initialValues } from "../user/pages/ManageUsersRoot";
import {
  toTitleCase,
  UserStatusConverter,
} from "../../_shared/shared.functions";
import { RegionalUserContext } from "../user/pages/RegionalUserRoot";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../analytics/hooks/useAnalytics";

interface TransformedUser {
  Name: string;
  EmailAddress: string;
  Status: string;
}

const fetchUsers = async (organisationId: number) => {
  try {
    return await userManagementService.getOrganisationUsersByRole$(
      Number(organisationId),
      RoleIds.LeadAdministrator,
    );
  } catch (err) {
    console.error("Error fetching users:", err);
    return [];
  }
};

const OrganisationOverview: React.FC = () => {
  const { organisation, setLeadUserFormValues } =
    useOutletContext<RegionalUserContext>();
  const { code: OdsCode } = useParams<{ code: string }>();
  const navigate = useNavigate();
  useDocumentTitle("Organisational overview");
  useAnalytics(["regional", "Organisational overview"]);
  const [organisationUsers, setOrganisationUsers] = useState<User[] | null>(
    null,
  );
  const [loading, setLoading] = useState(true);

  const headers = useMemo(
    () => [
      { displayName: "Name", key: "Name" },
      { displayName: "Email address", key: "EmailAddress" },
      { displayName: "Status", key: "Status" },
    ],
    [],
  );

  useEffect(() => {
    if (!organisation.Id) return;
    const loadUsers = async () => {
      setLoading(true);
      const users = await fetchUsers(organisation.Id);
      setOrganisationUsers(users);
      setLoading(false);
    };

    loadUsers();
  }, [organisation]);

  const transformUserData = (users: User[]): TransformedUser[] => {
    return users.map((user) => ({
      Name: `${toTitleCase(user.FirstName)} ${toTitleCase(user.LastName)}`,
      EmailAddress: user.Email,
      Status: UserStatusConverter(user.LastLoginDate),
    }));
  };

  const transformedData = useMemo(() => {
    return organisationUsers ? transformUserData(organisationUsers) : [];
  }, [organisationUsers]);

  const onAddUser = () => {
    setLeadUserFormValues(initialValues);
    navigate(`/regional/${OdsCode}/add-lead-user`);
  };

  return (
    <div className="nhsuk-width-container">
      <BackLink asElement="button" onClick={() => navigate("/regional")}>
        Back to all organisations
      </BackLink>

      <main className="nhsuk-main-wrapper" id="maincontent" role="main">
        <div className="nhsuk-grid-row">
          <div className="nhsuk-grid-column-two-thirds">
            <h1 className="nhsuk-heading-l">
              {organisation?.Name || "Loading..."}
            </h1>
            <p>
              You have invited this organisation to create an NHS Record a
              vaccination service account.
            </p>
          </div>
        </div>
        {loading ? (
          <Loading message="Loading added lead users" />
        ) : (
          <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-full">
              {organisationUsers?.length > 0 && (
                <NhsTable<TransformedUser>
                  tableCaption="Users"
                  headers={headers}
                  data={transformedData}
                />
              )}
              <Button onClick={onAddUser}>Add another user</Button>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};
export default OrganisationOverview;
