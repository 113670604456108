import { VaccineProgramIds } from "./vaccination.enums";

export function SetTouched(formik, fields: string[]) {
  for (const f of fields) {
    if (f) {
      formik.setFieldTouched(f);
    }
  }
}

export function HasErrors(errors, fields: string[]) {
  if (errors) {
    for (const f of fields) {
      const err = errors[f];
      if (err) return true;
    }
  }
  return false;
}

export function IncorrectInterval(
  lastVaccinationDate: Date,
  vaccineProgramId,
  selectedDate: string = null,
  dayGap: number = 91,
): boolean {
  if (
    lastVaccinationDate &&
    vaccineProgramId &&
    vaccineProgramId == VaccineProgramIds.Covid
  ) {
    const currentVaccinationDate = selectedDate
      ? new Date(selectedDate)
      : new Date();
    let intervalDate = new Date(lastVaccinationDate);
    intervalDate.setDate(lastVaccinationDate.getDate() + dayGap);
    return currentVaccinationDate < intervalDate;
  }
  return false;
}

const differenceInDays = (date1: Date, date2: Date): number => {
  return Math.floor(
    (date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24),
  );
};

/**
 * Return the number of days pregnancy has progressed
 */
const getDaysPregnant = (
  assessmentDate: Date,
  expectedDueDate: Date,
): number => {
  return 40 * 7 - differenceInDays(assessmentDate, expectedDueDate);
};

/**
 * Return true if we should show a warning for RSV based on due date
 * RSV is recommended from 28 weeks of pregnancy.
 */
export function showRsvExpectedDueDateWarning(
  assessmentDate: Date,
  expectedDueDate: Date,
) {
  const daysPregnant = getDaysPregnant(assessmentDate, expectedDueDate);

  if (daysPregnant < 28 * 7) {
    return true;
  } else {
    return false;
  }
}

/**
 * Return true if we should show a warning for Pertussis based on due date.
 * Pertussis is recommended from 16 to 32 weeks of pregnancy.
 */
export function showPertussisExpectedDueDateWarning(
  assessmentDate: Date,
  expectedDueDate: Date,
) {
  const daysPregnant = getDaysPregnant(assessmentDate, expectedDueDate);

  if (daysPregnant < 16 * 7 || daysPregnant >= 32 * 7) {
    return true;
  } else {
    return false;
  }
}
