import React, { useEffect, useState, useCallback } from "react";
import { useFormik, FormikHelpers } from "formik";
import NhsAutocompleteSuggestionsV2 from "../../_shared/components/form/nhs-autocomplete-input/NhsAutocompleteSuggestionsV2";
import NhsAutocompleteInputV2 from "../../_shared/components/form/nhs-autocomplete-input/NhsAutocompleteInputV2";
import { BackLink, Button, ErrorSummary } from "nhsuk-react-components";
import regionalService from "../../_shared/services/regional/regional.service";
import { Loading } from "../../_shared/components/Loading";
import organisationService from "../../_shared/services/organisation/organisation.service";
import { scrollToElementId, toTitleCase } from "../../_shared/shared.functions";
import { object, string } from "yup";
import { NhsdOrganisation } from "../../_shared/shared.models";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../analytics/hooks/useAnalytics";
import { useUser } from "../user/UserProvider";
import { noSpecialCharsRegexForODSSearch } from "../../_shared/shared.validation";

interface AutocompleteOption {
  name: string;
  value: string;
}

const ERROR_MESSAGES = {
  EMPTY_SEARCH: "Search for an organisation",
  SELECT_ORGANISATION: "Select an organisation from the search",
  ORG_ALREADY_ADDED: "You have already added this organisation to your region",
  ORG_ALREADY_ADDED_REGION:
    "The {regionName} region has already added this organisation",
};

const RegionalFindOrganisation: React.FC = () => {
  const navigate = useNavigate();
  const user = useUser();
  useDocumentTitle("Find an organisation to invite");
  useAnalytics(["regional", "invite-organisation"]);

  const [inputValue, setInputValue] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<AutocompleteOption[]>(
    [],
  );
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [fetchedData, setFetchedData] = useState<NhsdOrganisation[]>([]);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [hoveredField, setHoveredField] = useState<string | null>(null);
  const [userSummaryErrors, setUserSummaryErrors] = useState<string | null>(
    null,
  );
  const [selectedOrganisation, setSelectedOrganisation] =
    React.useState<NhsdOrganisation | null>(null);

  const clearErrors = () => {
    setErrorMsg(null);
    setUserSummaryErrors(null);
  };

  const formik = useFormik({
    initialValues: {
      organisation: "",
    },
    validationSchema: object().shape({
      organisation: string()
        .required("Search for an organisation")
        .matches(
          noSpecialCharsRegexForODSSearch,
          "Enter a valid organisation name",
        ),
    }),
    validate: () => {
      if (formik.errors.organisation != null) {
        setUserSummaryErrors(formik.errors.organisation);
      }
    },
    onSubmit: async (
      values: { organisation: string },
      actions: FormikHelpers<{ organisation: string }>,
    ) => {
      if (values.organisation === "") {
        setErrorMsg(ERROR_MESSAGES.EMPTY_SEARCH);
        setUserSummaryErrors(ERROR_MESSAGES.EMPTY_SEARCH);
        return;
      }

      if (selectedOrganisation === null) {
        setErrorMsg(ERROR_MESSAGES.SELECT_ORGANISATION);
        setUserSummaryErrors(ERROR_MESSAGES.SELECT_ORGANISATION);
        return;
      }

      if (errorMsg !== null) {
        return;
      }

      navigate(`/regional/add-organisation/${selectedOrganisation.OrgId}`);
    },
  });

  const fetchOrganisations = useCallback(async () => {
    if (
      inputValue.trim().length > 2 &&
      isTyping &&
      noSpecialCharsRegexForODSSearch.test(inputValue)
    ) {
      setIsLoading(true);
      try {
        const results = await regionalService.searchOrganisations$(
          inputValue.toUpperCase(),
        );
        const options = results.map((org) => ({
          name: `${toTitleCase(org.Name)}, ${org.PostCode.toUpperCase()}, (${org.OrgId})`,
          value: org.OrgId,
        }));
        setFetchedData(results);
        setFilteredOptions(options);
        setIsMenuOpen(true);
      } catch (err) {
        console.error("Failed to fetch organisations from ORD", err);
      } finally {
        setIsLoading(false);
      }
    } else {
      setFilteredOptions([]);
      setIsMenuOpen(false);
    }
  }, [inputValue, isTyping]);

  useEffect(() => {
    fetchOrganisations();
  }, [fetchOrganisations]);

  const handleCustomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSelectedOrganisation(null);
    clearErrors();

    setInputValue(value);
    setIsTyping(true);
    formik.setFieldValue("organisation", value);
  };

  const handleSuggestionClick = async (
    name: string,
    data: AutocompleteOption,
  ) => {
    clearErrors();
    setInputValue(data.name);
    setIsMenuOpen(false);
    setIsTyping(false);
    const foundOption = filteredOptions.find(
      (option) => option.name === data.name,
    );
    const selectedOption = fetchedData.find(
      (org) => org.OrgId === foundOption?.value,
    );
    setSelectedOrganisation(selectedOption || null);
    formik.setFieldValue(name, data.name);

    if (selectedOption) {
      const organisationInfo = await organisationService.checkOrganisation$(
        selectedOption.OrgId,
      );
      if (organisationInfo.Exists) {
        const message =
          organisationInfo.Region?.Id === user?.Region?.Id
            ? ERROR_MESSAGES.ORG_ALREADY_ADDED
            : ERROR_MESSAGES.ORG_ALREADY_ADDED_REGION.replace(
                "{regionName}",
                organisationInfo.Region?.Name || "another region",
              );
        setErrorMsg(message);
        setUserSummaryErrors(message);
      }
    }
  };

  return (
    <>
      <BackLink asElement="button" onClick={() => navigate("/regional")}>
        Back
      </BackLink>
      <form onSubmit={formik.handleSubmit}>
        <div className="nhsuk-grid-row">
          <div className="nhsuk-grid-column-two-thirds">
            {userSummaryErrors && (
              <ErrorSummary id="formErrors">
                <ErrorSummary.Title>There is a problem</ErrorSummary.Title>
                <ErrorSummary.Body>
                  <ErrorSummary.List>
                    <ErrorSummary.Item>
                      <button
                        className={`anchor-style ${hoveredField === "organisation" ? "hovered-color" : "error-color"}`}
                        onMouseEnter={() => setHoveredField("organisation")}
                        onMouseLeave={() => setHoveredField(null)}
                        onClick={() => scrollToElementId("organisation")}
                      >
                        {userSummaryErrors}
                      </button>
                    </ErrorSummary.Item>
                  </ErrorSummary.List>
                </ErrorSummary.Body>
              </ErrorSummary>
            )}
          </div>
          <div className="nhsuk-grid-column-two-thirds">
            <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
              <h1 className="nhsuk-fieldset__heading">
                Find an organisation to invite
              </h1>
            </legend>

            <NhsAutocompleteInputV2
              style={{ marginBottom: "0 !important" }}
              label={"NHS organisation or community pharmacy"}
              hint={"Search by name or enter full ODS code"}
              name="organisation"
              formik={formik}
              handleCustomChange={handleCustomChange}
              customErrorMsg={errorMsg}
            />

            {isLoading && (
              <div className="nhsuk-u-margin-top-5">
                <Loading message="Searching for organisation" />
              </div>
            )}

            {isMenuOpen && !isLoading && (
              <NhsAutocompleteSuggestionsV2
                inputValue={formik.values.organisation}
                name="organisation"
                suggestions={filteredOptions}
                handleOnClick={handleSuggestionClick}
              />
            )}

            <Button type="submit" className="nhsuk-u-margin-top-5">
              Continue
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default RegionalFindOrganisation;
