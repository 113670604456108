import * as React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Formik, Field, Form, FormikErrors } from "formik";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../analytics/hooks/useAnalytics";
import {
  AddBatchAnalyticsSubCategories,
  SiteVaccinesAnalyticsPageNames,
  SiteVaccinesPageTitles,
  SiteVaccinesPaths,
} from "./site-vaccines.enums";
import { RemoveTime } from "../../_shared/shared.functions";
import { SiteVaccines } from "./site-vaccines.models";
import { BatchDto } from "../batch/batch.models";
import siteVaccinesService from "./site-vaccines.service";
import { useState } from "react";
import { object, string } from "yup";
import { Button, TextInput } from "nhsuk-react-components";
import { FormikDateInput } from "../../_shared/components/formik/DateRangeInput";
import FormikErrorSummary from "../../_shared/components/formik/ErrorSummary";
import { DateInputValue } from "../../_shared/shared.models";
import { dateSchema } from "../../_shared/shared.date.validation";

interface BatchFormInput {
  VaccineProgramId: string;
  OtherBatchNumber: string;
  ExpiryDate: DateInputValue;
}

export default function AddEditBatch() {
  const location = useLocation();
  const navigate = useNavigate();

  const [options, setOptions] = useState(location.state?.[0]);
  const userSites = location.state?.[1];
  const [siteVaccines, setSiteVaccines] = useState(
    location.state?.[2] as SiteVaccines[],
  );
  const [batch, setBatch] = useState(location.state?.[3] as BatchDto);
  const siteVaccineBatchesStateData = location.state?.[4] as BatchDto[];
  const addOrEdit = location.state?.[5];
  const siteVaccine = location.state?.[7] as SiteVaccines;
  const [formikErrors, setFormikErrors] = useState<
    FormikErrors<BatchFormInput>
  >({});

  const pageAction = addOrEdit?.IsEditBatch
    ? SiteVaccinesPageTitles.EditBatch
    : SiteVaccinesPageTitles.AddBatch;
  useDocumentTitle(pageAction);

  const subCategory2 = addOrEdit?.IsEditBatch
    ? AddBatchAnalyticsSubCategories.SubCategory2Edit
    : AddBatchAnalyticsSubCategories.SubCategory2Add;
  useAnalytics([
    "service",
    SiteVaccinesAnalyticsPageNames.PrimaryCategory,
    AddBatchAnalyticsSubCategories.SubCategory1,
    subCategory2,
  ]);

  const batchExpiryDate =
    batch && batch?.ExpiryDate?.includes("T")
      ? RemoveTime(batch?.ExpiryDate)
      : batch && !batch?.ExpiryDate?.includes("T")
        ? batch?.ExpiryDate
        : "";

  React.useEffect(() => {
    if (batch) {
      const checkAddressExists = async () => {
        if (!batch.AddrLn1) {
          let orgDetails = await siteVaccinesService.nhsdOrganisation$(
            batch.Code,
          );

          setBatch((prevState) => ({
            ...prevState,
            AddrLn1: orgDetails?.AddressLine1,
            AddrLn2: orgDetails?.AddressLine2,
            Town: orgDetails?.Town,
            County: orgDetails?.County,
            PostCode: orgDetails?.PostCode,
          }));
        }
      };

      checkAddressExists();
    }
  }, [batch]);

  const validationSchema = object({
    OtherBatchNumber: string()
      .required("Enter the batch number")
      .test(
        "no-whitespace",
        "Enter the batch number",
        (value) => value.trim() !== "",
      )
      .test("alphanumeric", "Enter a valid batch number", (value) =>
        /^[A-Za-z0-9-]*$/.test(value.trim()),
      ),

    ExpiryDate: dateSchema
      .label("Expiry date")
      .test(
        "batch-exists",
        "There is already a batch with this number and expiry date",
        async function (value: DateInputValue) {
          const { OtherBatchNumber } = this.parent; // Access other form values
          if (!OtherBatchNumber || !value.day || !value.month || !value.year) {
            return true; // Skip validation if data is incomplete
          }
          const expiryDate = dateValuesToString(value);
          const batchExists = await siteVaccinesService.batchExists$({
            ...batch,
            BatchNumber: OtherBatchNumber.trim(),
            ExpiryDate: expiryDate,
          });
          return !batchExists; // Validation fails if batch exists
        },
      ),
  });

  async function handleSubmit(values) {
    batch.BatchNumber = values.OtherBatchNumber.trim();
    batch.ExpiryDate = dateValuesToString(values.ExpiryDate);

    if (addOrEdit?.IsEditBatch) {
      const returnedResult =
        await siteVaccinesService.editSiteVaccineBatch$(batch);

      setSiteVaccines(returnedResult.SitesVaccines);
      setOptions((prevState) => ({
        ...prevState,
        Sites: returnedResult.Sites,
      }));

      navigate(SiteVaccinesPaths.SiteVaccineBatchesList, {
        state: [
          options,
          returnedResult.Sites,
          returnedResult.SitesVaccines,
          returnedResult.SitesVaccines.find(
            (sv) => sv.SiteVaccineId === siteVaccine.SiteVaccineId,
          ) as SiteVaccines,
        ],
      });
    } else {
      navigate(SiteVaccinesPaths.ConfirmSiteVaccineBatch, {
        state: [
          options,
          userSites,
          siteVaccines,
          batch,
          siteVaccineBatchesStateData,
          addOrEdit,
          null,
          siteVaccine,
        ],
      });
    }
  }

  const dateValuesToString = (values: DateInputValue): string | null => {
    if (!values.day || !values.month || !values.year) return null;

    const year = String(values.year).padStart(4, "0");
    const month = String(values.month).padStart(2, "0");
    const day = String(values.day).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const stringToDateValues = (dateString: string): DateInputValue => {
    if (!dateString) return { day: "", month: "", year: "" };

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) return { day: "", month: "", year: "" };

    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (0-indexed, so add 1)
    const day = date.getDate().toString().padStart(2, "0"); // Get day

    return { year, month, day };
  };

  const InitialValues: BatchFormInput = {
    VaccineProgramId: batch?.VaccineProgramId.toString() || "",
    OtherBatchNumber: batch?.BatchNumber?.toUpperCase() || "",
    ExpiryDate: batch
      ? stringToDateValues(batchExpiryDate)
      : { day: "", month: "", year: "" },
  };

  function BackButtonSVG() {
    return (
      <svg
        className="nhsuk-icon nhsuk-icon__chevron-left"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        aria-hidden="true"
        height="24"
        width="24"
      >
        <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
      </svg>
    );
  }

  const validate = async (values: BatchFormInput) => {
    const errors: FormikErrors<BatchFormInput> = {};
    const context = { allowFuture: true };
    try {
      await validationSchema.validate(values, {
        context,
        abortEarly: false,
      });
    } catch (err: any) {
      err.inner.forEach((error: any) => {
        errors[error.path] = error.message;
      });
    }
    setFormikErrors(errors);

    return errors;
  };

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      initialValues={InitialValues}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ errors, handleSubmit, setFieldValue, values }) => (
        <div className="nhsuk-grid-row">
          <div className="nhsuk-grid-column-two-thirds">
            <>
              {!addOrEdit ? (
                <>
                  <div className="nhsuk-back-link">
                    <Link
                      className="nhsuk-back-link__link"
                      to={{ pathname: SiteVaccinesPaths.AddVaccine }}
                      state={[options, userSites, siteVaccines, batch]}
                    >
                      <BackButtonSVG /> Back
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  {addOrEdit?.IsAddEditBatchFromSiteBatchesList === true ? (
                    <div className="nhsuk-back-link">
                      <Link
                        className="nhsuk-back-link__link"
                        to={{
                          pathname: SiteVaccinesPaths.SiteVaccineBatchesList,
                        }}
                        state={[options, userSites, siteVaccines, siteVaccine]}
                      >
                        <BackButtonSVG /> Back
                      </Link>
                    </div>
                  ) : (
                    <>
                      {addOrEdit?.IsAddEditBatchFromSiteVaccinesList ===
                        true && (
                        <div className="nhsuk-back-link">
                          <Link
                            className="nhsuk-back-link__link"
                            to={{
                              pathname: SiteVaccinesPaths.SiteVaccinesList,
                            }}
                            state={[null, [] as SiteVaccines[]]}
                          >
                            <BackButtonSVG /> Back
                          </Link>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </>
            <Form onSubmit={handleSubmit} className="nhsuk-form">
              <fieldset>
                <legend>
                  <h1 className="nhsuk-heading-l">
                    {addOrEdit?.IsEditBatch ? "Edit batch" : "Add batch"}
                  </h1>
                </legend>
              </fieldset>

              <FormikErrorSummary errors={formikErrors} />

              <Field name="OtherBatchNumber">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    id="OtherBatchNumber"
                    label="Batch number"
                    error={meta.touched && meta.error ? meta.error : ""}
                    width={20}
                  />
                )}
              </Field>

              <div className="nhsuk-form-group">
                <Field
                  name="ExpiryDate"
                  component={FormikDateInput}
                  label="Expiry date"
                />
              </div>
              <Button type="submit">
                {addOrEdit?.IsEditBatch ? "Save changes" : "Continue"}
              </Button>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
}
