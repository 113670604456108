import React, { useEffect, useRef } from "react";
import { Modal as BootstrapModal } from "bootstrap";

interface ModalProps extends React.PropsWithChildren {
  isOpen: boolean;
  backdrop?: boolean | "static";
  keyboard?: boolean;
}

/**
 * Modal dialog which simply wraps Bootstrap's modal component.
 * This is not very "reacty", but it's a step towards removing bootstrap completely.
 */
export const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  backdrop = true,
  keyboard = true,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const myModalRef = useRef<BootstrapModal | null>(null);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    if (!myModalRef.current) {
      myModalRef.current = new BootstrapModal(modalRef.current, {
        keyboard,
        backdrop: backdrop,
        focus: true,
      });
    }

    if (isOpen) {
      myModalRef.current.show();
    } else {
      myModalRef.current.hide();
    }

    // Make sure we hide the modal when the component is unmounted
    return () => {
      myModalRef.current.hide();
    };
  }, [isOpen, backdrop, keyboard]);

  return (
    <div
      ref={modalRef}
      className="modal fade"
      tabIndex={-1}
      aria-hidden={!isOpen}
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};
