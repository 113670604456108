import React from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import FormikErrorSummary from "../formik/ErrorSummary";

interface Checkbox {
  id: string;
  label: string;
  hint?: string;
}

interface CheckboxGroupProps {
  title: string;
  checkboxes: Checkbox[];
  selectedCheckboxes: string[];
  setSelectedCheckboxes: (value: string[]) => void;
  errorMessage: string;
}

interface FormValues {
  items: { [key: string]: boolean };
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  title,
  checkboxes,
  selectedCheckboxes,
  setSelectedCheckboxes,
  errorMessage,
}) => {
  const initialValues: FormValues = {
    items: checkboxes.reduce(
      (acc, item) => {
        if (selectedCheckboxes !== null) {
          acc[item.id] = selectedCheckboxes.includes(item.id);
        } else {
          acc[item.id] = false;
        }
        return acc;
      },
      {} as { [key: string]: boolean },
    ),
  };

  const validationSchema = Yup.object({
    items: Yup.object().test("at-least-one-checked", errorMessage, (value) => {
      return Object.values(value).some((v) => v);
    }),
  });

  const handleSubmit = (values: FormValues) => {
    const selectedCheckboxes = Object.keys(values.items).filter(
      (key) => values.items[key],
    );
    setSelectedCheckboxes(
      selectedCheckboxes.length > 0 ? selectedCheckboxes : [],
    );
  };

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors }) => (
        <Form>
          {<FormikErrorSummary errors={errors} />}
          <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
            <h1 className="nhsuk-fieldset__heading">{title}</h1>
          </legend>
          {errors.items && typeof errors.items === "string" && (
            <div className="nhsuk-error-message">{errors.items}</div>
          )}
          <FieldArray name="items">
            {() => (
              <div id="items" className="nhsuk-checkboxes">
                {checkboxes.map((checkbox, i) => (
                  <div key={i} className="nhsuk-checkboxes__item">
                    <Field
                      className="nhsuk-checkboxes__input"
                      type="checkbox"
                      name={`items.${checkbox.id}`}
                      id={`items.${checkbox.id}`}
                      aria-describedby={
                        checkbox.hint && `items.${checkbox.label}-hint`
                      }
                    />
                    <label
                      className="nhsuk-label nhsuk-checkboxes__label"
                      htmlFor={`items.${checkbox.id}`}
                    >
                      {checkbox.label}
                    </label>
                    {checkbox.hint && (
                      <div
                        className="nhsuk-hint nhsuk-checkboxes__hint"
                        id={`items.${checkbox.id}-hint`}
                      >
                        {checkbox.hint}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
          <button type="submit" className="nhsuk-button nhsuk-u-margin-top-5">
            Continue
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default CheckboxGroup;
