import React from "react";
import { ClinicianRoleIds, LegalMechanismIds } from "../vaccination.enums";
import { RadioValues } from "../../../_shared/shared.enums";
import NhsYesNo from "../../../_shared/components/form/NhsYesNo";
import NhsSelect from "../../../_shared/components/form/NhsSelect";
import NhsInput from "../../../_shared/components/form/NhsInput";
import { RemoveValues } from "../../../_shared/shared.functions";
import { Button } from "nhsuk-react-components";

interface VaccinationStepConsentProps {
  formFields: any;
  form: any;
  options: any;
  handleSaveAndReturn: () => void;
  handleContinue: () => void;
}

const VaccinationStepConsent = ({
  formFields,
  form,
  options,
  handleSaveAndReturn,
  handleContinue,
}: VaccinationStepConsentProps) => {
  const handleConsented = () => {
    const fields = [
      "ConsentTypeId",
      "NameOfPersonConsenting",
      "RelationshipToPatient",
    ];
    if (form.values?.Consented === RadioValues.true) {
      RemoveValues(form, ["NoConsentReasonId"]);
    } else {
      RemoveValues(form, fields);
    }
  };

  return (
    <>
      <NhsYesNo
        name="Consented"
        formFields={formFields}
        formik={form}
        lableYes="Yes, they consent"
        onClick={handleConsented}
      ></NhsYesNo>

      {form.values?.Consented === RadioValues.false && (
        <NhsSelect
          name="NoConsentReasonId"
          options={options?.NoConsentReasons}
          formFields={formFields}
          formik={form}
        ></NhsSelect>
      )}
      {form.values?.Consented === RadioValues.true && (
        <>
          <NhsSelect
            name="ConsentTypeId"
            options={options?.ConsentTypes}
            formFields={formFields}
            formik={form}
          ></NhsSelect>
          {form.values?.ConsentTypeId && form.values?.ConsentTypeId !== "1" && (
            <>
              <NhsInput
                name="NameOfPersonConsenting"
                formFields={formFields}
                formik={form}
              ></NhsInput>
              <NhsInput
                name="RelationshipToPatient"
                formFields={formFields}
                formik={form}
              ></NhsInput>
            </>
          )}
        </>
      )}

      <NhsSelect
        name="ConsentClinicianId"
        options={
          form?.values.LegalMechanismId === LegalMechanismIds.PGD
            ? options?.Clinicians
            : options?.Clinicians.filter((c) =>
                c.Roles.some((r) => r == ClinicianRoleIds.ConsentClinician),
              )
        }
        optionValue={"ClinicianId"}
        formFields={formFields}
        formik={form}
        disabled={form?.values.LegalMechanismId === LegalMechanismIds.PGD}
      ></NhsSelect>

      {form.values?.Consented === RadioValues.false && (
        <Button
          type="button"
          secondary={true}
          className="float-start"
          onClick={handleSaveAndReturn}
        >
          Save and return
        </Button>
      )}

      {form.values?.Consented === RadioValues.true && (
        <Button type="button" onClick={handleContinue}>
          Continue
        </Button>
      )}
    </>
  );
};

export default VaccinationStepConsent;
