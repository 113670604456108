import { appAxios, appAxiosWithErrors } from "../../_shared/axios";
import { PageType, SessionStorageKeys } from "../../_shared/shared.enums";
import { Option } from "../../_shared/shared.models";
import { Vaccination, VaccinationOptions } from "./vaccination.models";

export const persistedVaccinationFields = [
  "SiteId",
  "VaccineProgramId",
  "ConsentVaccineId",
  "Eligible",
  "AssessmentDate",
  "LegalMechanismId",
  "AssessmentClinicianId",
  "AssessmentOutcomeId",
  "Consented",
  "ConsentTypeId",
  "ConsentClinicianId",
  "Vaccinated",
  "VaccinationDate",
  "CareModelId",
  "VaccineId",
  "BatchNumber",
  "VaccinatingClinicianId",
] as const;

type PersistedVaccination = Pick<
  Vaccination,
  (typeof persistedVaccinationFields)[number]
>;

// A valid persisted vaccination object must have all the fields in persistedVaccinationFields
const isPersistedVaccination = (obj: any): obj is PersistedVaccination => {
  return persistedVaccinationFields.every((field) => field in obj);
};

class VaccinationService {
  private basePath = "api/vaccination/";
  private options: string;
  private includeDeleted: boolean | null = null;
  private forceRefresh: boolean = false;

  async options$(includeDeleted: boolean = false): Promise<VaccinationOptions> {
    // If forceRefresh is false, and cached options exist, and includeDeleted matches, use the cached options
    if (
      !this.forceRefresh &&
      this.options &&
      (this.includeDeleted === includeDeleted || this.includeDeleted === null)
    ) {
      this.includeDeleted = includeDeleted;
      return JSON.parse(this.options);
    }

    // Fetch fresh data from the API, since forceRefresh is true or cache conditions don't match
    return await appAxios
      .get(`${this.basePath}options?includeDeleted=${includeDeleted}`)
      .then((res) => {
        this.options = JSON.stringify(res.data);
        this.includeDeleted = includeDeleted;
        this.forceRefresh = false;
        return res.data;
      });
  }

  forceRefreshOptions() {
    this.forceRefresh = true;
  }

  async vaccines$(
    siteId: string,
    vaccinationDate: string = null,
  ): Promise<Option[]> {
    const vaccinationDateParam = vaccinationDate
      ? `&vaccinationDate=${vaccinationDate}`
      : "";
    const url = `${this.basePath}vaccines?siteId=${siteId}${vaccinationDateParam}`;

    return await appAxios.get(url).then((res) => res.data);
  }

  async get$(id: string): Promise<Vaccination> {
    return await appAxios.get(`${this.basePath}get?id=${id}`).then((res) => {
      for (var i in res.data) {
        res.data[i] = res.data[i]?.toString();
      }
      return res.data;
    });
  }

  async vaccinesWithActiveBatches$(
    siteId: string,
    vaccinationDate: string = null,
    vaccineProgramId: string,
  ): Promise<Option[]> {
    const vaccinationDateParam = vaccinationDate
      ? `&vaccinationDate=${vaccinationDate}`
      : "";
    const url = `${this.basePath}VaccinesWithActiveBatches?siteId=${siteId}&vaccineProgramId=${vaccineProgramId}${vaccinationDateParam}`;

    return await appAxios.get(url).then((res) => res.data);
  }

  async addOrEdit$(
    pageType,
    vaccination: Vaccination,
    idempotencyKey: string = "",
  ): Promise<Vaccination> {
    if (pageType === PageType.Add) {
      this.saveLastVaccination(vaccination);
    }

    for (var i in vaccination) {
      if (!vaccination[i]) {
        delete vaccination[i];
      }
    }

    return await appAxiosWithErrors
      .post(`${this.basePath}${pageType}`, vaccination, {
        headers: {
          "Idempotency-Key": idempotencyKey,
        },
      })
      .then((res) => {
        return res.data;
      });
  }

  async delete$(id: any): Promise<any> {
    return await appAxios
      .get<any>(`${this.basePath}Delete?id=${id}`)
      .then((res) => {
        return res.data;
      });
  }

  saveLastVaccination(vaccination: Vaccination) {
    // Store only the fields that are in the persistedVaccinationFields array
    const lastVaccination = Object.fromEntries(
      persistedVaccinationFields.map((field) => [field, vaccination[field]]),
    ) as PersistedVaccination;

    window.sessionStorage.setItem(
      SessionStorageKeys.LastVaccination,
      JSON.stringify(lastVaccination),
    );
  }

  getLastVaccination(): PersistedVaccination | null {
    const lastVaccinationJson = window.sessionStorage.getItem(
      SessionStorageKeys.LastVaccination,
    );
    if (!lastVaccinationJson) return null;

    try {
      let lastVaccination = JSON.parse(lastVaccinationJson);
      if (isPersistedVaccination(lastVaccination)) {
        return lastVaccination;
      } else {
        return null;
      }
    } catch (e) {
      console.error("Error parsing last vaccination", e);
      return null;
    }
  }

  static get instance() {
    return vaccinationService;
  }
}

const vaccinationService = new VaccinationService();

export default vaccinationService;
