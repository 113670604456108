import React, { useEffect, useState } from "react";
import {
  TextInput,
  Radios,
  Button,
  ErrorSummary,
} from "nhsuk-react-components";
import { UserFormValues } from "../user/pages/ManageUsersRoot";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { User } from "../user/user.models";
import { RoleIds } from "../user/user.enums";
import { roleList } from "../user/user.functions";
import { emailValidationSchema } from "../../_shared/shared.validation";
import { scrollToElementId } from "../../_shared/shared.functions";
import { Link } from "react-router-dom";

const OrganisationUserForm: React.FC<{
  userFormValues: UserFormValues;
  initialFocusField: string;
  isEditing?: boolean;
  onSubmit: (values: UserFormValues) => Promise<void>;
  users: User[];
  canDeactivateUsers: boolean;
  isEditingSelf?: boolean;
  manyLeadAdminsExist?: boolean;
}> = ({
  userFormValues,
  initialFocusField,
  isEditing = false,
  onSubmit,
  users,
  canDeactivateUsers,
  isEditingSelf,
  manyLeadAdminsExist,
}) => {
  const [showErrorSummary, setShowErrorSummary] = useState(false);
  const [hoveredField, setHoveredField] = useState<string | null>(null);
  const [focusField, setFocusField] = useState(initialFocusField);
  const rolesOption = roleList();
  const leadAdminOption = rolesOption.find(
    (role) => role.Id === RoleIds.LeadAdministrator,
  );
  const recorderOption = rolesOption.find(
    (role) => role.Id === RoleIds.Recorder,
  );
  const adminOption = rolesOption.find(
    (role) => role.Id === RoleIds.Administrator,
  );

  let emailDuplicateCache: { [email: string]: boolean } = {};
  let emailDomainCache: { [email: string]: boolean } = {};

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("Enter first name")
      .max(100, "First name must be 100 characters or less"),
    lastName: Yup.string()
      .required("Enter last name")
      .max(100, "Last name must be 100 characters or less"),
    email: emailValidationSchema(
      isEditing,
      emailDomainCache,
      emailDuplicateCache,
    ),
    clinician: Yup.string().required("Select if they are a clinician"),
    permission: Yup.string()
      .required("Select permission level")
      .test(
        "is-last-lead-admin",
        "There must be at least one Lead administrator. You cannot change the permission for this user.",
        async (value: string) => {
          if (!value) return false;
          if (isEditing && userFormValues.permission === leadAdminOption.Name) {
            const leadAdmins = users.filter(
              (user) =>
                user.Roles.includes(RoleIds.LeadAdministrator) &&
                user.LastLoginDate !== null,
            );
            if (
              leadAdmins.length === 1 &&
              leadAdmins[0].UserId === userFormValues.id &&
              leadAdminOption.Name !== value
            ) {
              return false;
            }
          }
          return true;
        },
      ),
  });

  useEffect(() => {
    if (focusField !== "") {
      scrollToElementId(focusField);
      setFocusField("");
    }
  }, [focusField]);

  useEffect(() => {
    if (showErrorSummary) {
      setFocusField("formErrors");
    }
  }, [showErrorSummary]);

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={userFormValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors, submitCount }) => (
        <Form className="nhsuk-grid-row nhsuk-grid-column-two-thirds">
          {submitCount > 0 && Object.keys(errors).length > 0 && (
            <ErrorSummary id="formErrors">
              <ErrorSummary.Title>There is a problem</ErrorSummary.Title>
              <ErrorSummary.Body>
                <ErrorSummary.List>
                  {Object.keys(errors).map((fieldName, index) => (
                    <ErrorSummary.Item key={index}>
                      <button
                        className={`anchor-style ${
                          hoveredField === fieldName
                            ? "hovered-color"
                            : "error-color"
                        }`}
                        onMouseEnter={() => setHoveredField(fieldName)}
                        onMouseLeave={() => setHoveredField(null)}
                        key={index}
                        onClick={() => scrollToElementId(fieldName)}
                      >
                        {errors[fieldName]}
                      </button>
                    </ErrorSummary.Item>
                  ))}
                </ErrorSummary.List>
              </ErrorSummary.Body>
            </ErrorSummary>
          )}
          {!isEditing && (
            <div>
              <Field name="firstName">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    id="firstName"
                    label="First name"
                    error={meta.touched && meta.error ? meta.error : ""}
                    width={20}
                  />
                )}
              </Field>
            </div>
          )}
          {!isEditing && (
            <div>
              <Field name="lastName">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    id="lastName"
                    label="Last name"
                    error={meta.touched && meta.error ? meta.error : ""}
                    width={20}
                  />
                )}
              </Field>
            </div>
          )}
          {!isEditing && (
            <div>
              <Field name="email">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    id="email"
                    label="NHS email address"
                    error={meta.touched && meta.error ? meta.error : ""}
                    width={30}
                  />
                )}
              </Field>
            </div>
          )}
          <fieldset>
            <legend className="nhsuk-fieldset__legend">
              Are they a registered clinician?
            </legend>
            <Field name="clinician">
              {({ field, meta }) => (
                <Radios
                  hint="Only registered clinicians can assess the patient and record their consent"
                  id="clinician"
                  name="clinician"
                  error={meta.touched && meta.error ? meta.error : ""}
                  {...field}
                >
                  <Radios.Radio value="Yes" checked={field.value === "Yes"}>
                    Yes
                  </Radios.Radio>
                  <Radios.Radio value="No" checked={field.value === "No"}>
                    No
                  </Radios.Radio>
                </Radios>
              )}
            </Field>
          </fieldset>
          <fieldset>
            <legend className="nhsuk-fieldset__legend">Permission level</legend>
            <Field name="permission">
              {({ field, meta }) => (
                <Radios
                  id="permission"
                  name="permission"
                  {...field}
                  error={meta.touched && meta.error ? meta.error : ""}
                >
                  <Radios.Radio
                    value={recorderOption.Name}
                    hint="Record vaccinations only"
                    checked={field.value === recorderOption.Name}
                  >
                    {recorderOption.Name}
                  </Radios.Radio>
                  <Radios.Radio
                    value={adminOption.Name}
                    hint="Record vaccinations, create reports and manage vaccines"
                    checked={field.value === adminOption.Name}
                  >
                    {adminOption.Name}
                  </Radios.Radio>
                  <Radios.Radio
                    value={leadAdminOption.Name}
                    hint="Record vaccinations, create reports, manage vaccines and users"
                    checked={field.value === leadAdminOption.Name}
                  >
                    {leadAdminOption.Name}
                  </Radios.Radio>
                </Radios>
              )}
            </Field>
          </fieldset>
          <Button
            type="submit"
            className="nhsuk-button"
            disabled={isSubmitting}
            onClick={() => setShowErrorSummary(true)}
          >
            Continue
          </Button>

          {canDeactivateUsers && (
            <>
              {isEditingSelf ? (
                <>
                  {manyLeadAdminsExist ? (
                    <p>
                      Do you still need this account?{" "}
                      <Link
                        to={`/manage-users/deactivate/${userFormValues.id}`}
                      >
                        Deactivate your account
                      </Link>
                    </p>
                  ) : (
                    <p>
                      You cannot deactivate your account as you are the only
                      active lead administrator.
                    </p>
                  )}
                </>
              ) : (
                <p>
                  Are they no longer working here?{" "}
                  <Link to={`/manage-users/deactivate/${userFormValues.id}`}>
                    Deactivate this account
                  </Link>
                </p>
              )}
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default OrganisationUserForm;
