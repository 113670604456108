import React from "react";

interface SpinnerProps {
  className?: string;
}

/**
 * A bootstrap spinner component
 */
export const Spinner: React.FC<SpinnerProps> = ({ className }) => {
  return (
    <div className={`spinner-border ${className}`} role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};
