import React, { useEffect, useState } from "react";
import userService from "../user.service";
import useAnalytics from "../../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../../_shared/hooks/useDocumentTitle";
import {
  RoleIds,
  UserPageTitles,
  UserProfilePrimaryCategory,
} from "../user.enums";
import { NhsdOrganisation, Option } from "../../../_shared/shared.models";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { BackLink, TextInput } from "nhsuk-react-components";
import { ProfileUserName, User } from "../user.models";
import organisationService from "../../../_shared/services/organisation/organisation.service";
import regionalService from "../../../_shared/services/regional/regional.service";
import { matchRoleIdWithRole } from "./ManageUsersRoot";
import { ORGANISATION_LEAD_ADMINS_RETURN_LIMIT } from "../../../_shared/shared.constants";
import { useUser } from "../UserProvider";
import { SessionStorageKeys } from "../../../_shared/shared.enums";
import { useNavigate } from "react-router-dom";

interface UserNameFormValues {
  firstName: string;
  lastName: string;
}

export default function Profile() {
  useAnalytics(["service", UserProfilePrimaryCategory.PrimaryCategory]);
  useDocumentTitle(UserPageTitles.UserProfile);
  const [user, setUser] = useState<User | null>(null);
  const [leadAdmins, setLeadAdmins] = useState<User[]>([]);
  const [showUpdateNameForm, setShowUpdateNameForm] = useState(false);
  const [organisation, setOrganisation] = useState<
    NhsdOrganisation | undefined
  >(undefined);
  const navigate = useNavigate();
  const currentUser = useUser();
  var hasSelected = JSON.parse(
    sessionStorage.getItem(SessionStorageKeys.HasSelectedOrg),
  );

  const shouldHidePage = currentUser?.HasMultipleOrgs && !hasSelected;

  useEffect(() => {
    if (shouldHidePage) {
      navigate("/select-organisation");
    }
  }, [navigate, shouldHidePage]);

  useEffect(() => {
    if (user?.OrganisationId) {
      fetchOrganisation();
    }
  }, [user?.OrganisationId]);

  const fetchOrganisation = async () => {
    try {
      const organisation = await organisationService.getOrganisation$(
        user?.OrganisationId,
      );

      if (organisation?.Code) {
        const results = await regionalService.searchOrganisations$(
          organisation.Code,
          true,
        );

        if (results && results.length > 0) {
          setOrganisation(results[0]);
        }
      }
    } catch (error) {
      console.error(
        "Error fetching organisation or searching organisations:",
        error,
      );
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  const fetchUsersData = async () => {
    try {
      const loggedUser = await userService.getUser$();
      if (loggedUser) {
        setUser(loggedUser);

        if (
          !loggedUser.Roles.includes(RoleIds.LeadAdministrator) &&
          !loggedUser.Roles.includes(RoleIds.RegionalAdministrator) &&
          loggedUser.OrganisationId
        ) {
          const admins = await userService.getLeadAdmins$(
            ORGANISATION_LEAD_ADMINS_RETURN_LIMIT,
          );
          setLeadAdmins(admins);
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("Enter first name")
      .max(50, "First name must be 50 characters or less"),
    lastName: Yup.string()
      .required("Enter last name")
      .max(50, "Last name must be 50 characters or less"),
  });

  const handleBackClick = () => {
    setShowUpdateNameForm(false);
  };

  function roleHintList(): Option[] {
    return [
      {
        Id: RoleIds.Administrator,
        Name: "Can record vaccinations, create reports and manage vaccines",
      },
      {
        Id: RoleIds.LeadAdministrator,
        Name: "Can record vaccinations, create reports, manage vaccines and users",
      },
      { Id: RoleIds.Recorder, Name: "Can record vaccinations only" },
      { Id: RoleIds.RegionalAdministrator, Name: "Regional administrator" },
    ];
  }

  function matchRoleIdWithHint(roleIds: string[]): React.ReactNode {
    const hints = roleIds
      .map((roleId) => {
        return roleHintList().find((x) => x.Id === roleId)?.Name;
      })
      .filter(Boolean)
      .join(", ");
    return <>{hints}</>;
  }

  function onSubmit(values: UserNameFormValues) {
    const profileUserName: ProfileUserName = {
      Email: user.Email,
      FirstName: values.firstName,
      LastName: values.lastName,
    };
    userService.updateUserName$(profileUserName).then((result) => {
      if (result != null) {
        setUser(result);
        userService.refreshUserSessionName(profileUserName);
        setShowUpdateNameForm(false);
      }
    });
  }

  return (
    <div className="nhsuk-grid-row">
      <div className="nhsuk-grid-column-two-thirds">
        <div className="profile-page-container">
          {user
            ? showUpdateNameForm
              ? displayUpdateUserNameForm()
              : displayProfile()
            : null}
        </div>
      </div>
    </div>
  );

  function displayUpdateUserNameForm() {
    return (
      <>
        <BackLink asElement="button" onClick={() => handleBackClick()}>
          Back
        </BackLink>
        <div className="profile-form-container">
          <Formik<UserNameFormValues>
            initialValues={{
              firstName: user.FirstName,
              lastName: user.LastName,
            }}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values) => onSubmit(values)}
          >
            {({ isSubmitting, errors }) => (
              <Form>
                <fieldset className="nhsuk-fieldset">
                  <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--l">
                    <h1 className="nhsuk-fieldset__heading">
                      Update your name
                    </h1>
                  </legend>

                  <div>
                    <Field name="firstName">
                      {({ field, meta }) => (
                        <TextInput
                          {...field}
                          id="firstName"
                          label="First name"
                          error={meta.touched && meta.error ? meta.error : ""}
                          width={20}
                        />
                      )}
                    </Field>
                  </div>

                  <div>
                    <Field name="lastName">
                      {({ field, meta }) => (
                        <TextInput
                          {...field}
                          id="lastName"
                          label="Last name"
                          error={meta.touched && meta.error ? meta.error : ""}
                          width={20}
                        />
                      )}
                    </Field>
                  </div>
                  <button
                    className="nhsuk-button"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save and continue
                  </button>
                </fieldset>
              </Form>
            )}
          </Formik>
        </div>
      </>
    );
  }

  function displayProfile() {
    return (
      <>
        <fieldset>
          <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
            <h1 className="nhsuk-fieldset__heading">Your profile</h1>
          </legend>
        </fieldset>

        {user ? (
          <>
            <dl className="nhsuk-summary-list nhsuk-u-margin-bottom-4">
              <div className="nhsuk-summary-list__row">
                <dt className="nhsuk-summary-list__key">Name</dt>
                <dd className="nhsuk-summary-list__value">
                  {user.FirstName} {user.LastName}
                </dd>
                <dd className="nhsuk-summary-list__actions">
                  <button
                    className="nhs-link-button"
                    onClick={() => {
                      setShowUpdateNameForm(true);
                    }}
                  >
                    Change
                    <span className="nhsuk-u-visually-hidden">name</span>
                  </button>
                </dd>
              </div>

              <div className="nhsuk-summary-list__row">
                <dt className="nhsuk-summary-list__key">Email address</dt>
                <dd className="nhsuk-summary-list__value">{user.Email}</dd>
                <dd className="nhsuk-summary-list__actions">
                  <ul className="nhsuk-summary-list__actions-list"></ul>
                </dd>
              </div>

              {!user.IsRegionLead && (
                <div className="nhsuk-summary-list__row">
                  <dt className="nhsuk-summary-list__key">Organisation</dt>
                  <dd className="nhsuk-summary-list__value">
                    <div>{user.Organisation}</div>

                    <p className="app-summary__explanation nhsuk-hint">
                      {organisation?.AddressLine1} {organisation?.AddressLine2}
                      {organisation?.PostCode}
                    </p>
                  </dd>
                </div>
              )}

              <div className="nhsuk-summary-list__row">
                <dt className="nhsuk-summary-list__key">Permission level</dt>
                <dd className="nhsuk-summary-list__value">
                  {matchRoleIdWithRole(user.Roles)}
                  <div className="app-summary__explanation nhsuk-hint">
                    {matchRoleIdWithHint(user.Roles)}
                  </div>
                </dd>
                <dd className="nhsuk-summary-list__actions">
                  <span className="nhsuk-u-visually-hidden"></span>
                </dd>
              </div>
            </dl>
            {leadAdmins && leadAdmins.length > 0 && (
              <>
                <p>
                  Only lead administrators can change permission levels. The
                  lead administrator{leadAdmins.length > 1 ? "s" : ""} for{" "}
                  {user.Organisation}
                  {leadAdmins.length > 1 ? " are:" : " is "}
                  {leadAdmins.length === 1 ? (
                    <span key={leadAdmins[0].UserId}>
                      {leadAdmins[0].FirstName} {leadAdmins[0].LastName} (
                      {leadAdmins[0].Email})
                    </span>
                  ) : (
                    <ul className="nhsuk-list nhsuk-list--bullet">
                      {leadAdmins.map((x) => (
                        <li className="text-wrap-pretty" key={x.UserId}>
                          {x.FirstName} {x.LastName} ({x.Email})
                        </li>
                      ))}
                    </ul>
                  )}
                </p>
              </>
            )}

            <p>
              If you need to change your email address,{" "}
              <a href="/contact-us">contact support</a>.
            </p>
          </>
        ) : (
          <div className="pt-4 pb-4 background-white">
            <div className="alert alert-primary" role="alert">
              Not logged in!
            </div>
          </div>
        )}
      </>
    );
  }
}
