import React from "react";
import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <div className="nhsuk-grid-row">
      <div className="nhsuk-grid-column-two-thirds">
        <h1 className="nhsuk-heading-xl">404</h1>
        <p className="nhsuk-body">
          The page you are looking for was not found.
        </p>
        <Link to={"/home"} className="nhsuk-link">
          Back to Home
        </Link>
      </div>
    </div>
  );
}
