import { Button, Col, Row, TextInput } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FormikErrors } from "formik";
import { ScrollToBottom } from "../../_shared/shared.functions";
import {
  Patient,
  PatientSearchResponse,
  PatientSearchType,
} from "./patient.models";
import { object, string } from "yup";
import { ValidPostcode } from "../../_shared/shared.validation";
import patientService from "./patient.service";
import { DateInputValue, Option } from "../../_shared/shared.models";
import optionService from "../../_shared/option.service";
import NhsSelectV2 from "../../_shared/components/form/NhsSelectV2";
import { FormikDateInput } from "../../_shared/components/formik/DateRangeInput";
import { dateSchema } from "../../_shared/shared.date.validation";
import { Spinner } from "../../_shared/components/Spinner";

const formFields = {
  FirstName: { Label: "First name" },
  LastName: { Label: "Last name" },
  GenderId: { Label: "Gender (Optional)" },
  DateOfBirth: { Label: "Date of birth" },
  Postcode: { Label: "Full postcode (Optional)" },
};

export interface PatientFormInput {
  FirstName: string;
  LastName: string;
  GenderId: string;
  DateOfBirth: DateInputValue;
  Postcode: string;
}

interface Props {
  patientSearchType: PatientSearchType;
  setLoading: (loading: boolean) => void;
  setSearchValues: (patient: Patient) => void;
  setPatients: (patients: Patient[]) => void;
  setPatient: (patient: Patient) => void;
  setSubmitButtonClicked: (clicked: boolean) => void;
  setFormikErrors?: (errors: FormikErrors<PatientFormInput>) => void;
}

const PatientForm: React.FC<Props> = ({
  patientSearchType,
  setLoading,
  setSearchValues,
  setPatient,
  setPatients,
  setSubmitButtonClicked,
  setFormikErrors,
}) => {
  const [genderOptions, setGenderOptions] = useState<Option[]>([]);

  const validationSchema = object({
    FirstName: string()
      .min(2, "Minimum of two characters")
      .required("Enter the first name"),
    LastName: string().required("Enter the last name"),
    GenderId: string(),
    Gender: string(),
    Postcode: string().test(
      "isValidPostcode",
      "Enter the full postcode in the correct format",
      function (value) {
        return value ? ValidPostcode(value) : true;
      },
    ),
    DateOfBirth: dateSchema.label("Date of birth"),
  });

  const processResponse = async (response: PatientSearchResponse | null) => {
    if (!response || !response.PdsPatient) return;

    const pdsPatient = response.PdsPatient;
    const ravsPatientId = response?.RavsPatient?.PatientId;

    if (pdsPatient.TooManyReturnedResults) {
      setPatient(pdsPatient);
    } else if (ravsPatientId) {
      setPatient({ ...pdsPatient, PatientId: ravsPatientId });
    } else {
      setPatient(pdsPatient);
    }
  };

  useEffect(() => {
    const getGenderOptions = async () => {
      const response = await optionService.getCachedOption$("Genders");
      setGenderOptions(response);
    };
    getGenderOptions();
  }, []);

  const dateValuesToString = (values: DateInputValue): string | null => {
    if (!values.day || !values.month || !values.year) return null;

    const year = String(values.year).padStart(4, "0");
    const month = String(values.month).padStart(2, "0");
    const day = String(values.day).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const initialValues: PatientFormInput = {
    FirstName: "",
    LastName: "",
    GenderId: "",
    DateOfBirth: { day: "", month: "", year: "" },
    Postcode: "",
  };

  const handlePatientSearch = async (values: PatientFormInput) => {
    let patient = { ...values } as unknown as Patient;

    patient.DateOfBirth = dateValuesToString(values.DateOfBirth);

    if (values.GenderId) {
      patient.Gender = genderOptions.find(
        (g) => Number(g.Id) === Number(values.GenderId),
      )?.Name;
    }

    setSearchValues(patient);
    setLoading(true);

    try {
      if (patientSearchType.PdsSearch) {
        const response = await patientService.detailsSearch$(patient);
        await processResponse(response);
      } else if (patientSearchType.RavsSearch) {
        const patients = await patientService.ravsSearchh$(patient);
        setPatients(patients);
        setSubmitButtonClicked(true);
      }
    } catch (error) {
      console.error("Error during patient search:", error);
    } finally {
      setLoading(false);
      ScrollToBottom();
    }
  };

  const validate = async (values: PatientFormInput) => {
    const errors: FormikErrors<PatientFormInput> = {};

    try {
      await validationSchema.validate(values, { abortEarly: false });
    } catch (err: any) {
      err.inner.forEach((error: any) => {
        errors[error.path] = error.message;
      });
    }

    // Send errors to the parent component
    setFormikErrors(errors);

    return errors;
  };

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validate={validate} // Sends errors to the parent component
      onSubmit={handlePatientSearch}
    >
      {({ isSubmitting }) => (
        <Form>
          <Row>
            <Col width="one-half">
              <Field name="FirstName">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    id="FirstName"
                    label="First name"
                    error={meta.touched && meta.error ? meta.error : ""}
                    width={20}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col width="one-half">
              <Field name="LastName">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    id="LastName"
                    label="Last name"
                    error={meta.touched && meta.error ? meta.error : ""}
                    width={20}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col width="one-half">
              <NhsSelectV2
                label="Gender (Optional)"
                name="GenderId"
                options={genderOptions}
                formFields={formFields}
              />
            </Col>
          </Row>
          <Row>
            <Col width="one-third">
              <Field name="Postcode">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    id="Postcode"
                    label="Postcode"
                    error={meta.touched && meta.error ? meta.error : ""}
                    width={20}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <div className="nhsuk-form-group">
            <Field
              name="DateOfBirth"
              component={FormikDateInput}
              label="Date of birth"
            />
          </div>
          {isSubmitting ? <Spinner /> : <Button type="submit">Search</Button>}
        </Form>
      )}
    </Formik>
  );
};

export default PatientForm;
