import { APP_BASE_URL } from "./shared.constants";
import { PageType } from "./shared.enums";
import { PageDetails } from "../components/vaccination/vaccination.models";
import { RoleIds } from "../components/user/user.enums";

export function Redirect(uri) {
  const url = `${APP_BASE_URL}${uri}`;
  window.location.assign(url);
}

export function GetPageDetails(path): PageDetails {
  const pathArray = path.split("/");
  if (pathArray.length && pathArray.length > 1) {
    const details = {} as PageDetails;
    const lastValue = pathArray[pathArray.length - 1];
    if (isNaN(lastValue)) {
      details.PageType = lastValue;
    } else {
      details.PageType = pathArray[pathArray.length - 2];

      if (details.PageType == PageType.Add) {
        details.ParentId = lastValue;
      } else {
        details.Id = lastValue;
      }
    }
    return details;
  }

  throw new Error(`No page title found for path: ${path}`);
}

/**
 * Converts a string to title case. For example, 'LONDON ROAD' becomes 'London Road'.
 *
 * @param str: The string to convert.
 * @returns The title case string.
 */
export function toTitleCase(str: string): string {
  if (!str) return str;

  const lowerCaseWords = [
    "and",
    "or",
    "but",
    "nor",
    "so",
    "for",
    "yet",
    "a",
    "an",
    "the",
    "in",
    "on",
    "at",
    "to",
    "by",
    "with",
    "from",
    "of",
  ];

  return str
    .toLowerCase()
    .split(" ")
    .map((word, index) => {
      if (word === "nhs") {
        return "NHS";
      }
      if (index === 0 || !lowerCaseWords.includes(word)) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word;
      }
    })
    .join(" ");
}

/**
 * Converts an ISO date string to UK date string.
 *
 * @param  dateOfBirth: ISO date string.
 * @param  dateTo: ISO date string. Optional, if no date is supplied, today's date is used.
 * @returns number.
 */
export function GetAge(
  dateOfBirthStr: string,
  dateToStr: string = null,
): number {
  if (!dateOfBirthStr) throw new Error("No date of birth supplied!");

  var dateOfBirth = new Date(dateOfBirthStr);
  var dateTo = dateToStr ? new Date(dateToStr) : new Date();
  const yearDifference = dateTo.getFullYear() - dateOfBirth.getFullYear();
  var monthDifference = dateTo.getMonth() - dateOfBirth.getMonth();
  var age = yearDifference;

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && dateTo.getDate() < dateOfBirth.getDate())
  ) {
    age--;
  }

  if (age === 0)
    age = Number(((monthDifference + 12 * yearDifference) / 12).toFixed(2));

  return age;
}

/**
 * Converts an boolen value to Yes or No.
 *
 * @param  value boolean.
 */
export function BooleanToYesNo(value): string {
  if (value === true || value === "true") return "Yes";
  if (value === false || value === "false") return "No";
  return value;
}

/**
 * Converts an ISO date string to UK date string.
 *
 * @param  ISO date string.
 * @returns UK date string.
 */
export function IsoToUkDate(dateStr: string): string {
  if (dateStr) {
    const date = new Date(dateStr);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }
  return dateStr;
}

export function DateToLongFormat(dateStr: string): string {
  if (!dateStr) return "";
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  return date.toLocaleDateString("en-GB", options).replace(/,/g, "");
}

export function DateToLongFormatWithDay(dateStr: string): string {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  return date.toLocaleDateString("en-GB", options).replace(/,/g, "");
}

/**
 * Removes the time from an ISO date time string.
 *
 * @param  ISO date and time string.
 * @returns date string.
 */
export function RemoveTime(dateStr: string): string {
  if (dateStr) {
    const dateTimeArr = dateStr.split("T");
    if (dateTimeArr?.length) return dateTimeArr[0];
  }
  throw Error(`Incorrect date string`);
}

/**
 * Converts the ISO date time string to time.
 *
 * @param  ISO date and time string.
 * @returns time string in HHMM format.
 */
export function IsoDateToHhMmTime(dateStr: string): string {
  if (dateStr) {
    const dateTimeArr = dateStr.split("T");
    if (dateTimeArr?.length > 0) return dateTimeArr[1].slice(0, 5);
  }
  return dateStr;
}

/**
 * Converts an ISO date to 'DD MMMM YYYY' format
 *
 * @param ISO date string.
 * @returns Split date string
 */
export function IsoToDateFornat(dateStr: string): string {
  if (dateStr) {
    let date = new Date(dateStr);
    const day = date.toLocaleString("default", { day: "2-digit" });
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.toLocaleString("default", { year: "numeric" });
    return day + " " + month + " " + year;
  }
  return dateStr;
}

/**
 * Converts an Date type to 'DD MMMM YYYY' string format
 *
 * @param date date string.
 *  @returns 'DD MMMM YYYY'
 */
export function FormateDate(date: Date): string {
  const dateTimeFormat: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "long",
    year: "numeric",
  };
  return date.toLocaleDateString("en-GB", dateTimeFormat);
}

export function ScrollToTop() {
  window.scrollTo(0, 0);
}

export function ScrollToBottom() {
  window.scrollTo(0, document.body.scrollHeight);
}

export function RemoveValues(formik, fields: string[]) {
  for (const f of fields) {
    formik.setFieldValue(f, "");
  }
}

/**
 * Groups an array by key value
 *
 * @param array: any.
 * @param groupByKey: string.
 * @returns object array of arrays: [{groupbyKey, groupedArray}]
 */
export function GroupBy(
  xs: any[],
  f: (item: any) => string,
): Record<string, any[]> {
  if (!Array.isArray(xs)) {
    return {}; // Return an empty object if input is not a valid array
  }

  return xs.reduce((result, value) => {
    const key = f(value);
    if (!key) {
      throw new Error("Group key must be valid");
    }
    (result[key] || (result[key] = [])).push(value);
    return result;
  }, {});
}

/**
 * Three text fields DD MM YYYY focus change
 * Moves focus to next input field onChange event
 */
export function HandleFocusChange(event) {
  const { maxLength, value, name } = event.target;
  const [fieldName, fieldIndex] = name.split("_");

  // Check if they hit the max character length
  if (value.length >= maxLength) {
    // Check if it's not the last input field
    if (parseInt(fieldIndex, 9) < 3) {
      const nextSibling = document.querySelector<HTMLInputElement>(
        `input[name=${fieldName}_${parseInt(fieldIndex, 9) + 1}]`,
      );

      // If found, focus the next field
      if (nextSibling !== null) {
        nextSibling?.focus();
      }
    }
  }
}

/**
 * Formats NHS Number to XXX XXX XXXX
 *
 * @param NHS Number.
 * @returns Formatted NHS number
 */
export function FormatNhsNumber(nhsNumber: string): string {
  if (nhsNumber && nhsNumber.length >= 10) {
    return (
      nhsNumber.substring(0, 3) +
      " " +
      nhsNumber.substring(3, 6) +
      " " +
      nhsNumber.substring(6)
    );
  }
  return nhsNumber;
}

/**
 * Limits lenth of numeric inputs
 * maxLength not working for number input type but works for text type
 *
 * @param event
 * @param formik
 * @param maxLength
 */
export function HandleValueLength(e, formik, maxLength) {
  let value = e.target.value;
  const name = e.target.name;
  if (value.length > maxLength) {
    formik.setFieldValue(name, value.slice(0, maxLength));
  }
}

/**
 * Matches a given role name with its corresponding role ID.
 *
 * @param {string} roleName - The name of the role to be matched.
 * @returns {string | undefined} - The corresponding role ID if a match is found, otherwise undefined.
 */
export const matchRoleWithRoleId = (roleName: string): string | undefined => {
  const normalizedRoleName = roleName.replace(/\s+/g, "").toLowerCase();
  const matchedKey = Object.keys(RoleIds).find(
    (key) => normalizedRoleName === key.toLowerCase(),
  );
  return matchedKey ? RoleIds[matchedKey as keyof typeof RoleIds] : undefined;
};

/**
 *  Returns last login status based on lastLoggedIn
 *
 * This function determines the user's status based on whether the `lastLoggedIn` timestamp
 * is `null` or not. If the timestamp is not `null`, the user is considered "Active". If it
 * is `null`, the user is considered "Invited".
 *
 * @param {string | null} lastLoggedIn - The timestamp of the last time the user logged in, or `null`
 *                                       if the user has not logged in yet.
 * @returns {string} - Returns "Active" if `lastLoggedIn` is not `null`, otherwise returns "Invited".
 */
export const UserStatusConverter = (lastLoggedIn: string | null): string => {
  if (lastLoggedIn !== null) {
    return "Active";
  } else {
    return "Invited";
  }
};

/**
 * Smoothly scrolls to an element and sets focus on it.
 *
 * Finds the HTML element with the specified `elementId`, scrolls smoothly to it, and focuses on it.
 * If the element is not found, no action is performed.
 *
 * @param {string} elementId - The `id` of the HTML element to scroll to and focus on.
 * @returns {void} - No return value.
 */
export const scrollToElementId = (elementId: string) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
    element.focus();
  }
};

export function DateToShortFormat(dateStr: string): string {
  if (dateStr) {
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    return date.toLocaleDateString("en-GB", options).replace(/,/g, "");
  }
  return dateStr;
}
/**
 * Handles Errors link onlick event to focus on the field element
 * @param e (event)
 * @param fieldName
 */
export function HandleErrorFocusOnClickByName(e, fieldName) {
  e.preventDefault();
  document.getElementsByName(fieldName)[0]?.focus();
}

export function HandleErrorFocusOnClickById(e, id) {
  e.preventDefault();
  document.getElementById(id)?.focus();
}
