import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import patientService from "./patient.service";
import PatientDetails from "./PatientDetails";
import NhsBackLink from "../../_shared/components/NHSUK/NhsBackLink";
import {
  GetAge,
  IsoToDateFornat,
  ScrollToTop,
} from "../../_shared/shared.functions";
import { GenderName } from "./patient.functions";
import VaccinationLists from "../vaccination/VaccinationLists";
import { PatientPageTitles } from "./patient.enums";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../analytics/hooks/useAnalytics";
import { Loading } from "../../_shared/components/Loading";
import { Button, WarningCallout } from "nhsuk-react-components";
import { Patient as PatientModel } from "./patient.models";

export default function Patient() {
  const location = useLocation();
  const actionPerformedState = location.state ? location.state[0] : null;
  let primaryCategory1 = "patient";
  let subCategory1 = "";

  if (actionPerformedState) {
    if (actionPerformedState.ActionType === "add") {
      primaryCategory1 = "patient-details";
    } else if (actionPerformedState.ActionType === "edit") {
      subCategory1 = "edit";
    } else if (actionPerformedState.ActionType === "delete") {
      subCategory1 = "delete";
    }
  }

  useAnalytics(["service", primaryCategory1, subCategory1]);
  useDocumentTitle(PatientPageTitles.PatientDetails);

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [patient, setPatient] = useState<PatientModel | null>(null);
  const [showUpdateSuccess, setShowUpdateSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      getPatient();
    }
  }, [id]);

  const getPatient = async () => {
    try {
      const fetchedPatient = await patientService.get$(id!);
      setPatient(fetchedPatient);
    } finally {
      setLoading(false);
    }
  };

  const editPatient = async () => {
    if (patient && patient.PdsPatientDto) {
      const updatedPdsPatientDto = {
        ...patient.PdsPatientDto,
        PatientId: patient.PatientId,
        Address: patient.PdsPatientDto.Address.trim(),
      };

      try {
        setLoading(true);
        const updatedPatient =
          await patientService.upsert$(updatedPdsPatientDto);
        // Preserve existing vaccinations
        setPatient({
          ...updatedPatient,
          Vaccinations: patient.Vaccinations,
        });
        ScrollToTop();
        setShowUpdateSuccess(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <NhsBackLink url="/patient/search/nhs-number"></NhsBackLink>
          {patient && (
            <>
              <h1 className="nhsuk-heading-l">
                {patient.FirstName} {patient.LastName}
              </h1>
              {showUpdateSuccess && (
                <div className="col-12">
                  <div className="card mb-3 background-success-border">
                    <div className="card-header text-white background-success">
                      Patient details updated
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        RAVS now reflects {patient.FirstName} {patient.LastName}
                        's PDS patient details.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <PatientDetails patient={patient} />
              {patient.IsDeceased && (
                <WarningCallout>
                  <WarningCallout.Label>Important</WarningCallout.Label>
                  <p>
                    This patient has died according to the Personal Demographics
                    Service (PDS).
                  </p>
                </WarningCallout>
              )}
              {patient.PdsPatientDto && (
                <>
                  <div className="nhsuk-card">
                    <div className="nhsuk-card__content">
                      <h3>PDS patient details</h3>
                      <dl className="nhsuk-summary-list">
                        <div className="nhsuk-summary-list__row">
                          <dt className="nhsuk-summary-list__key">Name</dt>
                          <dd className="nhsuk-summary-list__value">
                            {patient.PdsPatientDto.FirstName}{" "}
                            {patient.PdsPatientDto.LastName}
                          </dd>
                        </div>
                        <div className="nhsuk-summary-list__row">
                          <dt className="nhsuk-summary-list__key">
                            NHS number
                          </dt>
                          <dd className="nhsuk-summary-list__value">
                            {patient.PdsPatientDto.NhsNumber}
                          </dd>
                        </div>
                        <div className="nhsuk-summary-list__row">
                          <dt className="nhsuk-summary-list__key">
                            Date of birth
                          </dt>
                          <dd className="nhsuk-summary-list__value">
                            <div>
                              {IsoToDateFornat(
                                patient.PdsPatientDto.DateOfBirth,
                              )}
                            </div>
                            <div>
                              (aged {GetAge(patient.PdsPatientDto.DateOfBirth)})
                            </div>
                          </dd>
                        </div>
                        <div className="nhsuk-summary-list__row">
                          <dt className="nhsuk-summary-list__key">Gender</dt>
                          <dd className="nhsuk-summary-list__value">
                            {GenderName(patient.PdsPatientDto.GenderId)}
                          </dd>
                        </div>
                        <div className="nhsuk-summary-list__row">
                          <dt className="nhsuk-summary-list__key">
                            Phone number
                          </dt>
                          <dd className="nhsuk-summary-list__value">
                            {patient.PdsPatientDto.Telephone}
                          </dd>
                        </div>
                        <div className="nhsuk-summary-list__row">
                          <dt className="nhsuk-summary-list__key">Address</dt>
                          <dd className="nhsuk-summary-list__value">
                            {patient.PdsPatientDto.Address},{" "}
                            {patient.PdsPatientDto.Postcode}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                  <Button
                    type="button"
                    className="float-end"
                    onClick={(e) => {
                      e.preventDefault();
                      editPatient();
                    }}
                  >
                    Update RAVS with PDS record
                  </Button>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </>
              )}
              <Button
                type="button"
                onClick={() =>
                  navigate(`/vaccination/add/${patient.PatientId}`)
                }
              >
                Choose vaccine
              </Button>
              <VaccinationLists
                vaccinations={
                  Array.isArray(patient.Vaccinations)
                    ? patient.Vaccinations
                    : []
                }
              />
            </>
          )}
        </>
      )}
    </>
  );
}
