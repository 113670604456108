import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useFormik } from "formik";
import { object, string } from "yup";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../analytics/hooks/useAnalytics";
import {
  AddSiteAnalyticsSubCategories,
  SiteVaccinesAnalyticsPageNames,
  SiteVaccinesPageTitles,
  SiteVaccinesPaths,
} from "./site-vaccines.enums";
import {
  HandleErrorFocusOnClickByName,
  Redirect,
  toTitleCase,
} from "../../_shared/shared.functions";
import { Paths } from "../../_shared/shared.enums";
import { SiteVaccines } from "./site-vaccines.models";
import NhsAutocompleteInput from "../../_shared/components/form/nhs-autocomplete-input/NhsAutocompleteInput";
import NhsAutocompleteSuggestions from "../../_shared/components/form/nhs-autocomplete-input/NhsAutocompleteSuggestions";
import { useDebounceCallback } from "usehooks-ts";
import { BatchDto } from "../batch/batch.models";
import siteVaccinesService from "./site-vaccines.service";
import type { Option } from "../../_shared/shared.models";
import { Button } from "nhsuk-react-components";
import { noSpecialCharsRegexForODSSearch } from "../../_shared/shared.validation";

export default function AddSite() {
  useAnalytics([
    "service",
    SiteVaccinesAnalyticsPageNames.PrimaryCategory,
    AddSiteAnalyticsSubCategories.SubCategory1,
    AddSiteAnalyticsSubCategories.SubCategory2,
  ]);
  useDocumentTitle(SiteVaccinesPageTitles.AddSite);

  const location = useLocation();
  const navigate = useNavigate();

  const redirect = () => {
    Redirect(Paths.Home);
    return null;
  };

  const optionsStateData =
    location && location.state ? (location.state[0] as any) : redirect();
  const userSitesStateData =
    location && location.state ? (location.state[1] as any) : redirect();
  const siteVaccinesStateData =
    location && location.state
      ? (location.state[2] as SiteVaccines[])
      : redirect();
  const batchStateData =
    location && location.state ? (location.state[3] as BatchDto) : redirect();
  const [isSuggestionsDropDownOpen, setIsSuggestionsDropDownOpen] =
    useState<boolean>(true);

  const [options] = React.useState(optionsStateData as any);
  const [userSites] = React.useState(userSitesStateData);
  const [siteVaccines] = React.useState(
    siteVaccinesStateData as SiteVaccines[],
  );
  const [batch, setBatch] = React.useState(batchStateData as BatchDto);

  const [noMatchingRecord, setNoMatchingRecord] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState<Option[]>([]);
  const [selectFromDropDown, setSelectFromDropDown] = React.useState(false);

  useEffect(() => {
    if (batch?.Site) {
      setIsSuggestionsDropDownOpen(false);
    } else {
      setIsSuggestionsDropDownOpen(true);
    }
  }, [batch?.Site]);

  const formik = useFormik({
    initialValues: {
      NoMatchingRecord: "false",
      Site: batch?.SiteField ?? "",
    },

    validationSchema: object().shape({
      NoMatchingRecord: string(),
      Site: string()
        .matches(noSpecialCharsRegexForODSSearch, "Enter a valid site name")
        .when(["NoMatchingRecord"], {
          is: (NoMatchingRecord) => NoMatchingRecord === "true",
          then: (schema) =>
            schema.required(
              `No matching record found with ${formik.values.Site}`,
            ),
        })
        .required("Select the site"),
    }),

    onSubmit: (values) => {
      setSelectFromDropDown(false);
      if (
        (!batch?.Site ||
          values.NoMatchingRecord === "true" ||
          noMatchingRecord) &&
        suggestions?.length === 0
      ) {
        setSuggestions([]);
        setNoMatchingRecord(true);
        formik.setFieldValue("NoMatchingRecord", "false");
        return;
      } else if (suggestions?.length > 0 && !batch?.Site) {
        setSelectFromDropDown(true);
        return;
      }
      batch.IsParent = true;
      navigate(SiteVaccinesPaths.AddVaccine, {
        state: [options, userSites, siteVaccines, batch],
      });
    },
  });

  const cache: Record<string, Option[]> = {};
  async function getSuggestions(value: string): Promise<Option[]> {
    if (cache[value]) {
      return cache[value];
    }
    if (value.length > 2) {
      if (!noSpecialCharsRegexForODSSearch.test(value)) {
        setIsSuggestionsDropDownOpen(false);
        return [];
      } else {
        const userAssignedOrganisationODScode =
          location?.state[3]?.OrganisationHierarchy?.OdsCode;
        setIsSuggestionsDropDownOpen(true);
        return await siteVaccinesService.FindSubOrganisationsUnderParent$(
          userAssignedOrganisationODScode,
          value,
        );
      }
    }

    return [];
  }

  async function processValue(value: string) {
    var result = await getSuggestions(value);
    cache[value] = result;
    if (result.length === 0) {
      formik.setFieldValue("NoMatchingRecord", "true");

      setSuggestions([]);
    } else {
      setSuggestions(result);
    }
  }

  const debounceFn = useDebounceCallback(
    (value: string) => processValue(value),
    100,
  );

  const memoisedDebouncedFn = React.useMemo(() => debounceFn, [debounceFn]);
  function handleCustomChange(event) {
    const value = event.target.value.trim();

    // Do nothing if the field is not actually changed
    if (batch?.SiteField === value) {
      return;
    }

    setNoMatchingRecord(false);
    setSelectFromDropDown(false);

    formik.setFieldValue("NoMatchingRecord", "false");

    setBatch((prevState) => ({
      ...prevState,
      Site: "",
      SiteField: value,
    }));

    memoisedDebouncedFn(value);
  }

  async function handleSuggestionClick(fieldName, data) {
    formik.setFieldValue(fieldName, data.Name);
    formik.setFieldValue("NoMatchingRecord", "false");
    setNoMatchingRecord(false);
    setSelectFromDropDown(false);
    try {
      let orgDetails = await siteVaccinesService.nhsdOrganisation$(data.OrgId);

      setBatch((prevState) => ({
        ...prevState,
        Code: data.OrgId,
        Site: toTitleCase(orgDetails.Name),
        SiteField: data.Name,
        AddrLn1: toTitleCase(orgDetails.AddressLine1),
        AddrLn2: toTitleCase(orgDetails.AddressLine2),
        Town: orgDetails.Town,
        County: orgDetails.County,
        PostCode: orgDetails.PostCode,
      }));
    } finally {
      setSuggestions([]);
    }
  }

  return (
    <>
      {location && location.state ? (
        <>
          <div className="nhsuk-back-link">
            <Link
              className="nhsuk-back-link__link"
              to={{ pathname: SiteVaccinesPaths.SiteVaccinesList }}
              state={[null, [] as SiteVaccines[]]}
            >
              <svg
                className="nhsuk-icon nhsuk-icon__chevron-left"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="24"
                width="24"
              >
                <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
              </svg>{" "}
              Back
            </Link>
          </div>

          <div className="nhsuk-grid-row mt-3">
            <div className="nhsuk-grid-column-full">
              {((formik.touched["Site"] && formik.errors["Site"]) ||
                noMatchingRecord ||
                selectFromDropDown) && (
                <>
                  <div
                    className="nhsuk-error-summary"
                    aria-labelledby="error-summary-title"
                    role="alert"
                  >
                    <h2
                      className="nhsuk-error-summary__title"
                      id="error-summary-title"
                    >
                      There is a problem
                    </h2>
                    <div className="nhsuk-error-summary__body">
                      <ul className="nhsuk-list nhsuk-error-summary__list">
                        {formik.touched.Site && formik.errors.Site && (
                          <li className="nhsuk-error-message">
                            <Link
                              to="#"
                              onClick={(e) => {
                                HandleErrorFocusOnClickByName(e, "Site");
                              }}
                            >
                              {formik.errors.Site}
                            </Link>
                          </li>
                        )}

                        {noMatchingRecord && (
                          <li className="nhsuk-error-message">
                            <Link
                              to="#"
                              onClick={(e) => {
                                HandleErrorFocusOnClickByName(e, "Site");
                              }}
                            >
                              No matching record found with {formik.values.Site}
                            </Link>
                          </li>
                        )}

                        {selectFromDropDown && (
                          <li className="nhsuk-error-message">
                            <Link
                              to="#"
                              onClick={(e) => {
                                HandleErrorFocusOnClickByName(e, "Site");
                              }}
                            >
                              Select from searched result
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </>
              )}

              <form onSubmit={formik.handleSubmit}>
                <fieldset className="nhsuk-fieldset mb-3">
                  <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                    <h1 className="nhsuk-fieldset__heading">Choose site</h1>
                  </legend>
                </fieldset>

                <div
                  className={
                    "nhsuk-form-group " +
                    ((formik.touched["Site"] && formik.errors["Site"]) ||
                    noMatchingRecord ||
                    selectFromDropDown
                      ? "nhsuk-form-group--error"
                      : "")
                  }
                >
                  <fieldset
                    className="nhsuk-fieldset"
                    aria-describedby="select-site-hint"
                  >
                    <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--m">
                      Select a site where you'd like to add a vaccine
                    </legend>

                    <p>
                      You can{" "}
                      <a
                        href="https://digital.nhs.uk/services/organisation-data-service/request-a-new-code-or-update-organisation-details#site-request"
                        className="nhsuk-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        request a new site (opens in a new tab)
                      </a>
                    </p>

                    <div className="nhsuk-hint" id="select-site-hint">
                      Search by name or ODS code
                    </div>

                    <NhsAutocompleteInput
                      aria-label="Enter 3 or more characters to search"
                      name="Site"
                      placeholder={"Enter 3 or more characters to search"}
                      noMatchingRecord={noMatchingRecord}
                      selectFromDropDown={selectFromDropDown}
                      formik={formik}
                      onChange={formik.handleChange}
                      handleCustomChange={handleCustomChange}
                      autoComplete="off"
                    />
                    {isSuggestionsDropDownOpen && (
                      <NhsAutocompleteSuggestions
                        inputValue={formik.values.Site}
                        name="Site"
                        suggestions={suggestions}
                        handleOnClick={handleSuggestionClick}
                        nodatafoundname="site"
                      />
                    )}
                  </fieldset>
                </div>

                <div className="mt-3">
                  <Button type="submit">Continue</Button>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        redirect()
      )}
    </>
  );
}
