import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { PatientList } from "./PatientList";
import { Patient, PatientSearchType } from "./patient.models";
import {
  PatientSearchAnalyticsPrimaryCategory,
  PatientPageTitles,
  PatientSearchPdsAnayticsPageName,
} from "./patient.enums";
import useAnalytics from "../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import PatientForm, { PatientFormInput } from "./PatientForm";
import FormikErrorSummary from "../../_shared/components/formik/ErrorSummary";
import { FormikErrors } from "formik";

export default function PatientSearchPds() {
  useAnalytics([
    "service",
    PatientSearchAnalyticsPrimaryCategory.PrimaryCategory,
    PatientSearchPdsAnayticsPageName.SubCategory1,
  ]);
  useDocumentTitle(PatientPageTitles.PdsPatientSearch);

  const [loading, setLoading] = useState(false);
  const [searchValues, setSearchValues] = useState(null as Patient);
  const [patient, setPatient] = useState(null as Patient);
  const [formikErrors, setFormikErrors] = useState<
    FormikErrors<PatientFormInput>
  >({});

  const patientSearchType: PatientSearchType = {
    NhsNumberSearch: false,
    PdsSearch: true,
    RavsSearch: false,
  };

  return (
    <>
      <FormikErrorSummary errors={formikErrors} />
      <fieldset className="nhsuk-fieldset">
        <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
          <h1 className="nhsuk-fieldset__heading">Find a patient</h1>
        </legend>

        <div className="nhsuk-tabs" data-module="nhsuk-tabs">
          <ul
            className="nhsuk-tabs__list"
            aria-label="Patient search by demographics tab"
          >
            <li className="nhsuk-tabs__list-item">
              <NavLink to="/patient/search/nhs-number">By NHS number</NavLink>
            </li>
            <li className="nhsuk-tabs__list-item nhsuk-tabs__list-item--selected">
              <NavLink to=".">By demographics</NavLink>
            </li>
            <li className="nhsuk-tabs__list-item">
              <NavLink to="/patient/search/records">By local records</NavLink>
            </li>
          </ul>

          <div className="nhsuk-tabs__panel pt-0" id="by-nhs-number">
            <br />
            <h2 className="nhsuk-table__caption">Search by demographics</h2>
            <h3 className="nhsuk-label">
              Enter at least the first name, last name and date of birth.
              Entering more fields will increase your chances of finding a match
              immediately.
            </h3>
            <PatientForm
              patientSearchType={patientSearchType}
              setLoading={setLoading}
              setPatient={setPatient}
              setSearchValues={setSearchValues}
              setSubmitButtonClicked={() => {}}
              setPatients={() => {}}
              setFormikErrors={setFormikErrors}
            />
          </div>
        </div>

        {!loading ? (
          <PatientList
            patient={patient}
            patientSearchType={patientSearchType}
            patientSearchValues={searchValues}
            NhsNoSearchValues={null}
          />
        ) : null}
      </fieldset>
    </>
  );
}
