import React from "react";
import NhsOptionRadio from "../../../_shared/components/form/NhsOptionRadio";
import { IncorrectInterval } from "../vaccination.functions";
import ConsentVaccineRadios from "../ConsentVaccineRadios";
import { Loading } from "../../../_shared/components/Loading";
import { Button } from "nhsuk-react-components";

interface VaccinationStepVaccineProps {
  optionsLoading: boolean;
  options: any;
  formFields: {};
  form: any;
  vaccines: any[];
  batchExists: boolean;
  showVaccineWarning: (vaccineId: string) => boolean;
  lastCovidVaccinationDate: Date;
  handleContinue: () => void;
}

const VaccinationStepVaccine = ({
  optionsLoading,
  options,
  formFields,
  form,
  vaccines,
  batchExists,
  showVaccineWarning,
  lastCovidVaccinationDate,
  handleContinue,
}: VaccinationStepVaccineProps) => {
  return (
    <>
      {optionsLoading ? (
        <Loading />
      ) : options?.Sites?.length > 0 ? (
        <>
          <NhsOptionRadio
            name="SiteId"
            formFields={formFields}
            options={options?.Sites}
            formik={form}
          ></NhsOptionRadio>
          <NhsOptionRadio
            name="VaccineProgramId"
            formFields={formFields}
            options={options?.VaccinePrograms}
            formik={form}
          ></NhsOptionRadio>
          {form.values?.VaccineProgramId && (
            <>
              <div
                data-field="ConsentVaccineId"
                style={
                  form.errors.ConsentVaccineId && form.touched.ConsentVaccineId
                    ? {
                        borderLeft: "4px solid red",
                        paddingLeft: "10px",
                      }
                    : {}
                }
              >
                <ConsentVaccineRadios
                  name="ConsentVaccineId"
                  batchExists={batchExists}
                  formFields={formFields}
                  optionValue={"VaccineId"}
                  options={vaccines?.filter(
                    (v) =>
                      v.Assigned &&
                      form.values.VaccineProgramId &&
                      v.VaccineProgramId == form.values.VaccineProgramId,
                  )}
                  formik={form}
                ></ConsentVaccineRadios>
              </div>

              {showVaccineWarning(form.values.ConsentVaccineId) && (
                <div className="nhsuk-warning-callout">
                  <h2 className="nhsuk-warning-callout__label">
                    <span role="text">
                      <span className="nhsuk-u-visually-hidden">
                        Important:{" "}
                      </span>
                      Warning
                    </span>
                  </h2>
                  <p>
                    This vaccine may not be recommended for a person of this
                    age. Please check before proceeding or refer to a prescriber
                    for a Patient Specific Direction.
                  </p>
                </div>
              )}

              {IncorrectInterval(
                lastCovidVaccinationDate,
                form.values.VaccineProgramId,
              ) && (
                <div className="nhsuk-warning-callout">
                  <h2 className="nhsuk-warning-callout__label">
                    <span role="text">
                      <span className="nhsuk-u-visually-hidden">
                        Important:{" "}
                      </span>
                      Warning
                    </span>
                  </h2>
                  <p>
                    You may have not reached the minimal interval between
                    COVID-19 vaccine doses for this patient. This could depend
                    on the clinical circumstances. For vaccination guidance,
                    visit{" "}
                    <a
                      href="https://www.gov.uk/government/publications/covid-19-the-green-book-chapter-14a"
                      target="_blank"
                      rel="noreferrer"
                    >
                      COVID-19: The Green Book, chapter 14a (opens in a new
                      tab).
                    </a>
                  </p>
                </div>
              )}

              <div className="nhsuk-form-group">
                <Button type="button" onClick={handleContinue}>
                  Continue
                </Button>
              </div>
            </>
          )}
        </>
      ) : (
        <div>
          <p>
            You cannot record a vaccination yet, as there are no vaccines or
            batches set up.
          </p>
          <p>You need to be an administrator to add vaccines.</p>
        </div>
      )}
    </>
  );
};

export default VaccinationStepVaccine;
