import React from "react";
import { showRsvExpectedDueDateWarning } from "../vaccination.functions";

interface RSVDueDateWarningProps {
  assessmentDate: Date;
  expectedDueDate: Date;
}

const RSVDueDateWarning = ({
  assessmentDate,
  expectedDueDate,
}: RSVDueDateWarningProps) => {
  if (!showRsvExpectedDueDateWarning(assessmentDate, expectedDueDate)) {
    return null;
  }

  return (
    <div className="nhsuk-warning-callout">
      <h2 className="nhsuk-warning-callout__label">
        <span role="text">
          <span className="nhsuk-u-visually-hidden">Important: </span>
          Warning
        </span>
      </h2>
      <p>
        You have indicated the due date is{" "}
        {String(expectedDueDate.getDate()).padStart(2, "0")}/
        {String(expectedDueDate.getMonth() + 1).padStart(2, "0")}/
        {expectedDueDate.getFullYear()}. This vaccine is not routinely
        recommended before 28 weeks of pregnancy. For vaccination guidance,
        visit{" "}
        <a
          href="https://assets.publishing.service.gov.uk/media/669a5e37ab418ab05559290d/Green-book-chapter-27a-RSV-18_7_24.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Respiratory syncytial virus (RSV): The Green Book, chapter 27a.
        </a>
      </p>
    </div>
  );
};

export default RSVDueDateWarning;
