import React from "react";
import AccessControl from "../../../_shared/components/accessControl/AccessControl";
import { RoleIds } from "../user.enums";
import RegionOverview from "../../regional/RegionOverview";
import useDocumentTitle from "../../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../../analytics/hooks/useAnalytics";
import { useUser } from "../UserProvider";

const RegionalUserPage: React.FC = () => {
  useDocumentTitle("Region overview");
  useAnalytics(["regional"]);
  const user = useUser();

  return (
    <div className="regional-page-container">
      <AccessControl
        requiredRoles={[RoleIds.RegionalAdministrator]}
        matchAllRoles={false}
      >
        {user && <RegionOverview region={user.Region} />}
      </AccessControl>
    </div>
  );
};

export default RegionalUserPage;
