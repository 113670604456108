import { Region } from "../../user/user.models";

export interface Organisation {
  OrganisationId: number;
  Name: string;
  Code: string;
  NhsdCode?: string;
  NhsdTerm?: string;
  Type?: string;
  OrganisationStatusId?: number;
  OrganisationStatus?: OrganisationStatus;
  RegionId?: number;
}

interface OrganisationStatus {
  id: number;
  Name: string;
}

interface OrganisationStatus {
  id: number;
  Name: string;
}

export interface OrganisationExistenceInfo {
  Exists: boolean;
  Region?: Region;
  Id?: number;
  Name?: string;
  PrimaryRoleDescription?: string;
}

export enum OrganisationStatusIds {
  Invited = 1,
}

export interface LeadUserFormValues {
  firstName: string;
  lastName: string;
  email: string;
}
