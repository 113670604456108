import * as React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IsoToUkDate, ScrollToTop } from "../../_shared/shared.functions";
import {
  AddPatientAnalyticsPrimaryCategory,
  ConfirmAddPatientAnalyticsPageName,
  PatientPageTitles,
  PatientPaths,
} from "./patient.enums";
import { Patient } from "./patient.models";
import patientService from "./patient.service";
import useAnalytics from "../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import { PatientSearchNhsNumberNavigationState } from "./PatientSearchNhsNumber";
import { Button } from "nhsuk-react-components";

export default function ConfirmAddPatient() {
  useAnalytics([
    "services",
    AddPatientAnalyticsPrimaryCategory.PrimaryCategory,
    ConfirmAddPatientAnalyticsPageName.SubCategory1,
  ]);
  useDocumentTitle(PatientPageTitles.ConfirmAddPatient);
  const location = useLocation();
  const navigate = useNavigate();
  const [patientExists, setPatientExists] = React.useState(false);

  const redirect = () => {
    navigate(PatientPaths.NhsNumberPatientSearch);
  };

  let patient: Patient;
  if (location && location.state) {
    patient = location.state[0];
  } else redirect();

  const goToHome = () => {
    navigate(PatientPaths.NhsNumberPatientSearch);
  };

  const submitData = async () => {
    var returnedResult = await patientService.upsert$(patient);

    if (returnedResult?.PatientExists === true) {
      ScrollToTop();
      setPatientExists(true);
    } else {
      const state: PatientSearchNhsNumberNavigationState = {
        newPatient: returnedResult,
      };
      navigate(PatientPaths.NhsNumberPatientSearch, {
        state,
      });
    }
  };

  const errorMessageDescription = "Error! Patient already exists in RAVS.";

  return (
    <>
      {location && location.state ? (
        <>
          <Link
            className="nhsuk-back-link__link"
            to={{ pathname: PatientPaths.AddPatient }}
            state={[patient]}
          >
            <svg
              className="nhsuk-icon nhsuk-icon__chevron-left"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              height="24"
              width="24"
            >
              <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
            </svg>{" "}
            Back
          </Link>
          <div className="row mb-2">
            <div className="col-sm-9">
              <div className="nhsuk-grid-row mt-3">
                <div className="nhsuk-grid-column-full">
                  <fieldset className="nhsuk-fieldset mb-3">
                    <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                      <h1 className="nhsuk-fieldset__heading">
                        Check and confirm
                      </h1>
                    </legend>

                    {patientExists ? (
                      <div
                        className="nhsuk-error-summary"
                        aria-labelledby="error-summary-title"
                        role="alert"
                        tabIndex={-1}
                      >
                        <h2
                          className="nhsuk-error-summary__title"
                          id="error-summary-title"
                        >
                          There is a problem
                        </h2>
                        <div className="nhsuk-error-summary__body">
                          <p>{errorMessageDescription}</p>

                          <ul
                            className="nhsuk-list nhsuk-error-summary__list"
                            role="list"
                          >
                            <li key="ExistingSiteVaccineBatchLi">
                              {patient.FirstName} {patient.LastName} with date
                              of birth {IsoToUkDate(patient.DateOfBirth)}{" "}
                              already exists in RAVS.
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}

                    <dl className="nhsuk-summary-list">
                      {patient ? (
                        <>
                          <div
                            key="PatientFirstNameRow"
                            className="nhsuk-summary-list__row"
                          >
                            <dt
                              key="PatientFirstNameLabel"
                              className="nhsuk-summary-list__key"
                            >
                              First name
                            </dt>

                            <dd
                              key="PatientFirstNameData"
                              className="nhsuk-summary-list__value"
                            >
                              <div key="PatientFirstNameValue">
                                {patient.FirstName}
                              </div>
                            </dd>
                          </div>

                          <div
                            key="PatientLastNameRow"
                            className="nhsuk-summary-list__row"
                          >
                            <dt
                              key="PatientLastNameLabel"
                              className="nhsuk-summary-list__key"
                            >
                              Last name
                            </dt>

                            <dd
                              key="PatientLastNameData"
                              className="nhsuk-summary-list__value"
                            >
                              <div key="PatientLastNameValue">
                                {patient.LastName}
                              </div>
                            </dd>
                          </div>

                          <div
                            key="PatientGenderRow"
                            className="nhsuk-summary-list__row"
                          >
                            <dt
                              key="PatientGenderLabel"
                              className="nhsuk-summary-list__key"
                            >
                              Gender
                            </dt>

                            <dd
                              key="PatientGenderData"
                              className="nhsuk-summary-list__value"
                            >
                              <div key="PatientGenderValue">
                                {patient.Gender}
                              </div>
                            </dd>
                          </div>

                          <div
                            key="PatientPostCodeRow"
                            className="nhsuk-summary-list__row"
                          >
                            <dt
                              key="PatientPostCodeLabel"
                              className="nhsuk-summary-list__key"
                            >
                              Full postcode
                            </dt>

                            <dd
                              key="PatientPostCodeData"
                              className="nhsuk-summary-list__value"
                            >
                              <div key="PatientPostCodeValue">
                                {patient.Postcode}
                              </div>
                            </dd>
                          </div>

                          <div
                            key="PatientDateOfBirthRow"
                            className="nhsuk-summary-list__row"
                          >
                            <dt
                              key="PatientDateOfBirthLabel"
                              className="nhsuk-summary-list__key"
                            >
                              Date of birth
                            </dt>

                            <dd
                              key="PatientDateOfBirthData"
                              className="nhsuk-summary-list__value"
                            >
                              <div key="PatientDateOfBirthValue">
                                {IsoToUkDate(patient.DateOfBirth)}
                              </div>
                            </dd>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </dl>

                    <div className="mt-3">
                      <Button type="button" onClick={goToHome} reverse={true}>
                        Cancel
                      </Button>
                      <Button
                        type="button"
                        onClick={submitData}
                        className="float-end"
                      >
                        Confirm and save
                      </Button>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        redirect()
      )}
    </>
  );
}
