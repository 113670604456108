import React from "react";

import { Button as NHSButton } from "nhsuk-react-components";
import "./button.scss";

/**
 * Props extracted from the NHS Button component
 */
type NHSButtonProps = React.ComponentProps<typeof NHSButton>;

/**
 * Extra props that we want our RAVS button to have
 */
interface AdditionalProps {
  loading?: boolean;
}

const Button = (props: NHSButtonProps & AdditionalProps) => {
  const { loading = false, className = "", ...rest } = props;

  return (
    <NHSButton
      {...rest}
      className={`${className} ${loading ? "ravs-button--loading" : ""}`}
      disabled={loading}
      aria-live={loading ? "polite" : undefined}
      aria-label={loading ? "Loading" : undefined}
    />
  );
};

export default Button;
