import React, { useEffect, useState } from "react";
import usePageDetails from "../../vaccination/hooks/usePageDetails";
import { Loading } from "../../../_shared/components/Loading";
import { BackLink, WarningCallout } from "nhsuk-react-components";
import { useUser } from "../UserProvider";
import { useNavigate } from "react-router-dom";
import userDbService from "../userDb.service";
import AccessControl from "../../../_shared/components/accessControl/AccessControl";
import { AccountStatusIds, RoleIds } from "../user.enums";
import { SignOut } from "../user.functions";
import "./user.scss";
import { AccountStatusUser } from "../user.models";
import usePageSettings from "../../../_shared/hooks/usePageSettings";
import { Spinner } from "../../../_shared/components/Spinner";

export default function DeactivateUser() {
  usePageSettings(["service", "manage-users", "deactivate"]);
  const navigate = useNavigate();
  const { id } = usePageDetails();
  const [accountStatusUser, setAccountStatusUser] =
    useState<AccountStatusUser>(null);
  const user = useUser();
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    userDbService.accountStatusUser$(id).then((res) => {
      setAccountStatusUser(res);
    });
  }, []);

  const deactivateAccount = async () => {
    setSaving(true);
    await userDbService.deactivateUser$(id).finally(() => setSaving(false));
    navigate("/manage-users/deactivated-users");
  };

  const deactivateYourAccount = async () => {
    setSaving(true);
    await userDbService.deactivateUser$(id).finally(() => setSaving(false));
    SignOut();
  };

  return (
    <AccessControl requiredRoles={[RoleIds.LeadAdministrator]}>
      <BackLink
        asElement="button"
        onClick={() => navigate(`/manage-users/edit/${id}`)}
      >
        Back
      </BackLink>

      {accountStatusUser ? (
        <div className="nhsuk-grid-row">
          <div className="nhsuk-grid-column-two-thirds">
            {accountStatusUser.AccountStatusId === AccountStatusIds.Disabled ? (
              <div className="nhsuk-warning-callout">
                <WarningCallout.Label>Warning</WarningCallout.Label>
                <p>This user is already deactivated!</p>
              </div>
            ) : (
              <>
                {user.UserId === accountStatusUser.UserId ? (
                  <>
                    <h1 className="nhsuk-heading-l">Deactivate your account</h1>

                    <p>
                      Once you deactivate your account, you'll be signed out and
                      cannot sign in and use NHS Record a vaccination.
                    </p>
                    <p>
                      Your Okta account will remain active, so you can continue
                      to access other services.
                    </p>
                    <p>
                      Another lead admin can reactivate your account anytime.
                    </p>
                    {saving ? (
                      <Spinner className="float-start"></Spinner>
                    ) : (
                      <button
                        className="nhs-button-warning"
                        onClick={deactivateYourAccount}
                      >
                        Deactivate
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <h1 className="nhsuk-heading-l">
                      Deactivate {accountStatusUser.FirstName}{" "}
                      {accountStatusUser.LastName}
                    </h1>

                    <p>
                      Once you deactivate {accountStatusUser.FirstName}{" "}
                      {accountStatusUser.LastName} ({accountStatusUser.Email}),
                      they cannot sign in and use NHS Record a vaccination.
                      They'll receive an email to confirm their account has been
                      deactivated.
                    </p>
                    <p>
                      Their Okta account will remain active, so they can
                      continue to access other services.
                    </p>
                    <p>You can reactivate their account anytime.</p>
                    {saving ? (
                      <Spinner className="float-start"></Spinner>
                    ) : (
                      <button
                        className="nhs-button-warning"
                        onClick={deactivateAccount}
                      >
                        Deactivate
                      </button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </AccessControl>
  );
}
