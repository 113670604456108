import React, { useState, useCallback, useEffect } from "react";
import { BackLink, Button, SummaryList } from "nhsuk-react-components";
import EmailPreview from "../user-management/email-preview/email-preview";
import { NotifyTemplateType } from "../../_shared/shared.enums";
import { User } from "../user/user.models";
import { AccountStatusIds, RoleIds } from "../user/user.enums";
import {
  Organisation,
  OrganisationStatusIds,
} from "./models/organisation.models";
import organisationService from "../../_shared/services/organisation/organisation.service";
import userManagementService from "../../_shared/services/user-management/userManagement.service";
import { toTitleCase } from "../../_shared/shared.functions";
import useOktaService from "../../_shared/services/okta/okta.service";
import { Loading } from "../../_shared/components/Loading";
import { RegionalUserContext } from "../user/pages/RegionalUserRoot";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../analytics/hooks/useAnalytics";

function TextWithLineBreaks(text: string | null): JSX.Element | null {
  return (
    text && (
      <div>
        {text.split("\n").map((line, index) => (
          <span key={index}>
            {line}
            {index < text.split("\n").length - 1 && <br />}
          </span>
        ))}
      </div>
    )
  );
}

const CheckAndSend: React.FC = () => {
  const { leadUserFormValues, organisation, user, setFocusField } =
    useOutletContext<RegionalUserContext>();
  const navigate = useNavigate();
  useDocumentTitle("Check and send");
  useAnalytics(["regional", "organisation", "add-user", "check"]);
  const [hoveredField, setHoveredField] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [emailTemplate, setEmailTemplate] = useState<NotifyTemplateType>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const { code: OdsCode } = useParams<{ code: string }>();

  useEffect(() => {
    const checkEmailOkta = async (email: string) => {
      try {
        if (!email) {
          navigate("/regional");
        } else {
          setLoading(true);
          const exists = await useOktaService.checkOktaUserExistence$(email);

          const templateType = exists
            ? NotifyTemplateType.LeadUserWithoutOktaActivation
            : NotifyTemplateType.LeadUserWithOktaActivation;

          setEmailTemplate(templateType);
        }
      } catch (error) {
        console.error("Error checking if user exists:", error);
      } finally {
        setLoading(false);
      }
    };

    checkEmailOkta(leadUserFormValues.email);
  }, [leadUserFormValues.email, navigate]);

  const confirmAndSend = useCallback(async () => {
    if (!organisation) return;

    setIsSubmitted(true);

    const { firstName, lastName, email } = leadUserFormValues;

    const organisationDTO: Organisation = {
      Name: toTitleCase(organisation.Name),
      OrganisationId: organisation.Id,
      Code: OdsCode,
      Type: organisation.PrimaryRoleDescription,
      RegionId: user.Region.Id,
      OrganisationStatusId: OrganisationStatusIds.Invited,
    };

    const newUser: User = {
      UserId: null,
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Roles: [RoleIds.LeadAdministrator],
      OrganisationId: organisation.Id,
      AccountStatusId: AccountStatusIds.Enabled,
      RegionId: user.RegionId,
      IsRegionLead: false,
      Organisation: toTitleCase(organisation.Name),
    };

    try {
      if (!organisation.Exists) {
        const res = await organisationService.addOrganisation$(organisationDTO);
        newUser.OrganisationId = res.OrganisationId;
      }
      await userManagementService.addOrEdit$(newUser, "Add", true);
      navigate(`/regional/${OdsCode}`);
    } catch (error) {
      console.error(error);
    }
  }, [
    organisation,
    leadUserFormValues,
    OdsCode,
    user.Region.Id,
    user.RegionId,
    navigate,
  ]);

  const handleChange = (fieldName: string) => {
    if (fieldName === "organisationName") {
      navigate(`/regional/add-organisation`);
    } else {
      navigate("../add-lead-user");
      setFocusField(fieldName);
    }
  };

  const fields = [
    {
      key: "Organisation name",
      value: toTitleCase(organisation.Name),
      field: "organisationName",
    },
    {
      key: "Lead user",
      value: `${toTitleCase(leadUserFormValues.firstName)} ${toTitleCase(leadUserFormValues.lastName)} \n ${leadUserFormValues.email}`,
      field: "firstName",
    },
  ];

  return (
    <>
      <BackLink asElement="button" onClick={() => navigate(-1)}>
        Back
      </BackLink>
      <div className="nhsuk-grid-row">
        <div className="nhsuk-width-container">
          <h1 className="nhsuk-heading-l">Check and send</h1>
          <div className="nhsuk-grid-row nhsuk-grid-column-two-thirds">
            {!organisation?.Exists && (
              <SummaryList>
                {fields.map(({ key, value, field: fieldName }) => (
                  <SummaryList.Row key={key}>
                    <SummaryList.Key>{key}</SummaryList.Key>
                    <SummaryList.Value>
                      {TextWithLineBreaks(value)}
                    </SummaryList.Value>
                    <SummaryList.Actions>
                      <button
                        className={`anchor-style ${hoveredField === fieldName ? "hovered-color" : "default-color"}`}
                        onMouseEnter={() => setHoveredField(fieldName)}
                        onMouseLeave={() => setHoveredField(null)}
                        onClick={() => {
                          handleChange(fieldName);
                        }}
                      >
                        Change
                        <span className="nhsuk-u-visually-hidden">
                          {" "}
                          {key.toLowerCase()}
                        </span>
                      </button>
                    </SummaryList.Actions>
                  </SummaryList.Row>
                ))}
              </SummaryList>
            )}
            <div className="emailSentContainer">
              <p>This email will be sent to {leadUserFormValues.email}</p>
            </div>

            {loading && <Loading message={"Loading email preview"} />}

            {!loading && (
              <EmailPreview
                personalisation={{
                  FirstName: toTitleCase(leadUserFormValues.firstName),
                }}
                templateType={emailTemplate}
                email={leadUserFormValues.email}
              />
            )}

            <Button
              className="nhsuk-button"
              disabled={isSubmitted}
              onClick={confirmAndSend}
            >
              Confirm and send
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default CheckAndSend;
