import React from "react";
import OrganisationUserList from "../../user-management/OrganisationUserList";
import AccessControl from "../../../_shared/components/accessControl/AccessControl";
import { RoleIds } from "../user.enums";
import { useNavigate, useOutletContext } from "react-router-dom";
import useDocumentTitle from "../../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../../analytics/hooks/useAnalytics";
import { Button } from "nhsuk-react-components";
import { ManageUsersContext } from "./ManageUsersRoot";

const pageTitle = "Manage users";

const ManageUsersPage: React.FC = () => {
  const { users, isLoading, deactivatedUserCount } =
    useOutletContext<ManageUsersContext>();
  const navigate = useNavigate();
  useDocumentTitle(pageTitle);

  useAnalytics(["service", "manage-users"]);

  return (
    <AccessControl
      requiredRoles={[RoleIds.LeadAdministrator]}
      matchAllRoles={false}
    >
      <div className="nhsuk-grid-row">
        <div className="nhsuk-grid-column-full">
          <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
            <h1 className="nhsuk-fieldset__heading">{pageTitle}</h1>
          </legend>

          <Button onClick={() => navigate("./add")}>Add user</Button>
          <OrganisationUserList
            users={users}
            isLoading={isLoading}
            onClickEdit={(userId) => navigate(`./edit/${userId}`)}
            deactivatedUserCount={deactivatedUserCount}
          />
        </div>
      </div>
    </AccessControl>
  );
};

export default ManageUsersPage;
